const {
  NODE_ENV,
  REACT_APP_ENTITLEMENTREG__URL,
  REACT_APP__NEUTRIPOS_BASEURL,
  REACT_APP_TOKENSERVICE_URL,
  REACT_APP__APPREGISTRY_BASEURL,
  REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL,
  REACT_APP__EMAILREGISTRY__BASEURL,
  REACT_APP_API_salesregistry,
  REACT_APP_PAYMENT_BASE_URL,
  REACT_APP__LOYALTY_REGISTRY_BASEURL,
  REACT_APP_PAYMENT_CLIENT_KEY,
  REACT_APP_PAYMENT_CLIENT_ENVIRONMENT
} = process.env;

const globalValues = {
  baseURLS: {
    REACT_APP_ENTITLEMENTREG__URL: REACT_APP_ENTITLEMENTREG__URL,
    REACT_APP__NEUTRIPOS_BASEURL: REACT_APP__NEUTRIPOS_BASEURL,
    REACT_APP__LOYALTY_REGISTRY_BASEURL: REACT_APP__LOYALTY_REGISTRY_BASEURL,
    REACT_APP_TOKENSERVICE_URL: REACT_APP_TOKENSERVICE_URL,
    REACT_APP__APPREGISTRY_BASEURL: REACT_APP__APPREGISTRY_BASEURL,
    REACT_APP__RETAILPAXS__BASEURL: REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL,
    REACT_APP__EMAILREGISTRY__BASEURL: REACT_APP__EMAILREGISTRY__BASEURL,
    REACT_APP__SALES_REGISTRY_BASEURL: REACT_APP_API_salesregistry,
    REACT_APP_PAYMENT_BASE_URL: REACT_APP_PAYMENT_BASE_URL,
  },

  environment: {
    CURRENT_ENVIRONMENT: NODE_ENV,
    IS_DEVELOPMENT: NODE_ENV === 'development',
  },
  payment: {
    REACT_APP_PAYMENT_CLIENT_KEY: REACT_APP_PAYMENT_CLIENT_KEY,
    REACT_APP_PAYMENT_CLIENT_ENVIRONMENT: REACT_APP_PAYMENT_CLIENT_ENVIRONMENT
  }
};
export default globalValues;
