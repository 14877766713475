import Axios from "axios";
import { APIError, errorLogger } from "./apiErrorHandlers";
import endpointService from "./endpointService";

export const getStorePaymentDetails = async (requestBody = {}, token) => {
    try {
        const { ...body } = requestBody;
        return new Promise(async (resolve, reject) => {
            const apiReqUrl = endpointService.paymentServer.getStorePaymentMethods
            let headers = {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,

            };

            await Axios({
                method: "POST",
                url: apiReqUrl,
                data: body,
                headers,
            }).then((response) => {
                const { Status, Result } = response.data;
                let resolveValue = Status ? Result : null
                resolve(resolveValue)
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}
export const passPaymentMethodToDropin = async (requestBody = {}, token) => {
    try {
        const { ...body } = requestBody;
        return new Promise(async (resolve, reject) => {
            const apiReqUrl = endpointService.paymentServer.passMethodDataToDropIn
            let headers = {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,

            };

            await Axios({
                method: "POST",
                url: apiReqUrl,
                data: body,
                headers,
            }).then((response) => {
                const { Status, Result } = response.data;
                resolve(Result)
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}
export const sendPaymentDetails = async (requestBody = {}, token) => {
    try {
        return new Promise(async (resolve, reject) => {
            const apiReqUrl = endpointService.paymentServer.sendPaymentDetails
            let headers = {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,

            };

            await Axios({
                method: "POST",
                url: apiReqUrl,
                data: requestBody,
                headers,
            }).then((response) => {
                const { Status, Result } = response.data;
                let resolveValue = Status ? Result : null
                resolve(resolveValue)
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}