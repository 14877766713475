import React from "react";
import { Popup } from 'witmeg-ui-system';
import "./Modal.scss"
import successIc from "../../assets/success.svg"
import failedIc from "../../assets/failed.svg"


const Modal = ({ visibility, onPopupClose, type, headerText, subText }) => {
    return (
        <Popup
            className='Modal'
            modalVisible={visibility}
            modalTitle=""
            mask={true}
            centered
            onCancel={onPopupClose}
            closable={false}>
            <div className="modal-inner">
                <div className='inner-container'>
                    <img className="icon-container" src={type === "success" ? successIc : failedIc} />
                </div>
                <div className="modal-header-text">{headerText}</div>
                <div className="modal-sub-text">{subText}</div>
                <div className="btn-container" onClick={onPopupClose}>OK</div>
            </div>
        </Popup>
    )
}
export default Modal