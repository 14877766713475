import React, { useState } from "react";
import "./HospitalityAuth.scss"
import { Label } from 'witmeg-ui-system'
import { useHistory } from 'react-router'
import Modal from "../../components/Modal/Modal";
import CodeInput from "../../components/CodeInput/CodeInput";
import { decrypt } from "../../../../../../../lib/decrypt";

const HospitalityAccountVerification = () => {
    let history = useHistory();
    const [codes, setCodes] = useState(Array(6).fill(""));
    const [isVerifiedOpen, setIsVerifiedOpen] = useState(false)
    const [isFailedOpen, setIsFailedOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const initialValues = JSON.parse(decrypt(decodeURIComponent(sessionStorage.getItem('verificationtoken'))))
    const onVerify = () => {
        setIsLoading(true)
        let code = Number(codes.join(''))

        if (code === initialValues) {
            sessionStorage.setItem('regeligible', true);
            setIsVerifiedOpen(true);
            setIsLoading(false)
        }
        else {
            setIsFailedOpen(true);
            setIsLoading(false)
        }

    };
    return (
        <div className="HospitalityAuth">
            <div className="HospitalityAuth-inner">
                <div className="header-text-cont">Register for online shopping</div>
                <div className="auth-container-wrapper">
                    <div className="auth-container-wrapper-left">
                        <div className="auth-container-left-inner">
                            <div className="input-containers ">
                                <div className="form-label-wrapper"><span>*</span><Label className="label-text">Verification Code</Label></div>
                                <CodeInput codes={codes} setCodes={setCodes} />
                            </div>
                            <div className="button-conatiner" onClick={() => {
                                if (codes.join('') !== "" && !isLoading) {
                                    onVerify()
                                }
                            }}>{isLoading && <div className="loader-btn" />}Verify Now</div>
                            <div className="verification-content">We have sent an email with a verification to your email address. In order to complete the sign-up process, Please check your email and enter the verification code above. If you do not receive a confirmation email, please check your spam folder. Also, please verify that you entered a valid email address in our sign-up form. If you need assistance, please contact us.</div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal visibility={isVerifiedOpen} type="success" headerText="Verified successfully" subText="One more step to complete your profile" onPopupClose={() => {
                setIsVerifiedOpen(false);
                history.push("/account/create")
            }} />
            <Modal visibility={isFailedOpen} type="failed" headerText="The email verification code is invalid" subText="Did you recently request an account without verifying your email address? If your email address has not been verified, you may need to create a new account. If you are having trouble, see Account Help." onPopupClose={() => {
                setIsFailedOpen(false);
                history.push("/account/register")
            }} />

        </div>
    )
}
export default HospitalityAccountVerification