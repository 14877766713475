import Axios from "axios";
import { APIError, errorLogger } from "./apiErrorHandlers";
import { generateTokenForRetailPax, generateTokenForRetailPaxNuetriPos } from "./token";
import endpointService from "./endpointService";
import { LOCAL_STORAGE_ITEMS_NAMES } from "../../../../../utilities/helper";

//adding items to cart
export const addToCart = async (requestBody = {}) => {
    try {
        const { ...body } = requestBody;
        let token = await generateTokenForRetailPax();
        if (token) {
            return new Promise(async (resolve, reject) => {
                const apiReqUrl = endpointService.loyaltyRegistryServer.addItemToCart
                let headers = {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                };

                await Axios({
                    method: "POST",
                    url: apiReqUrl,
                    data: body,
                    headers,
                }).then((response) => {
                    const { Status, Result } = response.data;
                    let resolveValue = Status ? Result : null
                    resolve(resolveValue)
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }

}

//deleting cart item from cart by cartItem ID
export const deleteCartItem = async (itemID) => {
    try {
        let token = await generateTokenForRetailPax();
        const orderID = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID)
        if (token) {
            return new Promise(async (resolve, reject) => {
                const apiReqUrl = `${endpointService.loyaltyRegistryServer.order}/${orderID}/cart-item/remove?ItemID=${itemID}`
                let headers = {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                };
                await Axios({
                    method: "PUT",
                    url: apiReqUrl,
                    headers,
                }).then((response) => {
                    const { Status, Result } = response.data;
                    let resolveValue = Status ? Result : null
                    resolve(resolveValue)
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }

}

//update cart item quantity from cart by cartItem ID
export const updateCartItemQuantity = async (requestBody = {}) => {
    try {
        let token = await generateTokenForRetailPax();
        if (token) {

            return new Promise(async (resolve, reject) => {
                const { ...body } = requestBody;
                const orderID = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID)
                const apiReqUrl = `${endpointService.loyaltyRegistryServer.order}/${orderID}/item-qty/update`
                let headers = {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                };
                await Axios({
                    method: "PUT",
                    url: apiReqUrl,
                    data: body,
                    headers,
                }).then((response) => {
                    const { Status, Result } = response.data;
                    let resolveValue = Status ? Result : null
                    resolve(resolveValue)
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }

}

//get cart items by custermerID and storeID
export const getCartItemsToCart = async (customerID, storeID) => {
    try {
        let token = await generateTokenForRetailPax();
        if (token) {

            return new Promise(async (resolve, reject) => {
                const apiReqUrl = `${endpointService.loyaltyRegistryServer.order}/get?Email=${customerID}&StoreID=${storeID}`
                let headers = {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                };
                await Axios({
                    method: "GET",
                    url: apiReqUrl,
                    headers,
                }).then((response) => {
                    const { Status, Result } = response.data;
                    let resolveValue = Status ? Result : null
                    resolve(resolveValue)
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }

}

//place order
export const placeOrder = async (requestBody = {}) => {
    try {
        const { ...body } = requestBody;

        let token = await generateTokenForRetailPax();
        if (token) {
            return new Promise(async (resolve, reject) => {
                const apiReqUrl = `${endpointService.loyaltyRegistryServer.placeOrder}`
                let headers = {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                };
                await Axios({
                    method: "PUT",
                    url: apiReqUrl,
                    data: body,
                    headers,
                }).then((response) => {
                    const { Status, Result } = response.data;
                    let resolveValue = Status ? Result : null
                    resolve(resolveValue)
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }

}
//get floor details in table orders
export const getFloorDetails = async () => {
    try {
        let locationID = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)).cloudLocationID
        let token = await generateTokenForRetailPaxNuetriPos();
        if (token) {

            return new Promise(async (resolve, reject) => {
                const apiReqUrl = `${endpointService.nuetriposServer.getFloorData}/floor-list?location_id=${locationID}`
                let headers = {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                };
                await Axios({
                    method: "GET",
                    url: apiReqUrl,
                    headers,

                }).then((response) => {
                    const { Status, Result } = response.data;
                    let resolveValue = Status ? Result : null
                    resolve(resolveValue)
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }

}

//get order details
export const getOrderDetails = async (orderId, storeID) => {
    try {
        let token = await generateTokenForRetailPax();
        if (token) {
            return new Promise(async (resolve, reject) => {
                const apiReqUrl = `${endpointService.loyaltyRegistryServer.order}/${orderId}/cart/get?StoreID=${storeID}`
                let headers = {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                };
                await Axios({
                    method: "GET",
                    url: apiReqUrl,
                    headers,
                }).then((response) => {
                    const { Status, Result } = response.data;
                    let resolveValue = Status ? Result : null
                    resolve(resolveValue)
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }

}
//update Order Type
export const updateOrderTypeDetails = async (requestBody = {}) => {
    try {
        const { ...body } = requestBody;
        let token = await generateTokenForRetailPax();
        if (token) {

            return new Promise(async (resolve, reject) => {
                const apiReqUrl = `${endpointService.loyaltyRegistryServer.updateOrderType}`
                let headers = {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                };
                await Axios({
                    method: "POST",
                    url: apiReqUrl,
                    headers,
                    data: body,
                }).then((response) => {
                    const { Status, Result } = response.data;
                    let resolveValue = Status ? Result : null
                    resolve(resolveValue)
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }

}

// Get All Pages info that assigns for a Company ID
export const getCompanyAllPagesInfo = async (reqBody = {}) => {
    try {
        const { ...fullReqBodyData } = reqBody;
        const apiReqBody = { ...fullReqBodyData };

        let token = await generateTokenForRetailPax();

        const apiReqUrl = endpointService.witmegRetailPaxServer.getCompanyAllPagesInfo
        let headers = {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
        };

        const response = await Axios({
            method: "POST",
            url: apiReqUrl,
            data: apiReqBody,
            headers,
        });

        const { Status, Result } = response.data;

        return Status ? Result : null;

    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}
export const getOrderHistory = async (storeID) => {
    try {
        let token = await generateTokenForRetailPax();
        const userID = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID)
        if (token) {
            return new Promise(async (resolve, reject) => {
                const apiReqUrl = `${endpointService.loyaltyRegistryServer.getOrderHistory}?CustomerID=${userID}&StoreID=${storeID}`
                let headers = {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                };
                await Axios({
                    method: "GET",
                    url: apiReqUrl,
                    headers,
                }).then((response) => {
                    const { Status, Result } = response.data;
                    let resolveValue = Status ? Result : null
                    resolve(resolveValue)
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }

}