import React, { useEffect, useState } from "react"
import "./Drawer.scss"
import profile from "../../assets/profile.svg"
import close from "../../assets/close.svg"

import { useHistory } from 'react-router-dom';
import { isECommerceProcessPath } from "../../../../../utilities/helper";
import { getCompanyAllPagesInfo } from "../../../../../utilities/apiRequests/witmegRetailPaxServerRequests";
import config from "react-global-configuration";

const Drawer = ({ setIsDrawerOpen }) => {
    const history = useHistory()
    const [pages, setPages] = useState([])
    useEffect(() => {

        const _initData = async () => {
            const __response = await getCompanyAllPagesInfo({
                ID: "",
                CompanyID: config.get("companyID"),
            });

            let __pages = __response
                .filter((z) => z.DraftMainPagesInfoID === "")
                .map((y) => {
                    return {
                        ...y,
                        children: __response.filter(
                            (a) => a.DraftMainPagesInfoID === y.ID
                        ),
                    };
                });
            setPages(__pages);
        };
        _initData();
    }, []);
    const handleLogout = () => {
        setIsDrawerOpen(false)
        sessionStorage.clear()
        localStorage.clear()
        window.location.href = '/'
    };
    return (
        <div className="DrawerStyles" >

            <div className="drawer-container">
                <div className="profile-conatiner">
                    <div className="profile-conatiner-left">
                        <div className='drawer-sign-in-menu-icon'>
                            <img alt='' src={profile} className='drawer-sign-in-menu-icon-profile' />
                        </div>
                        <div>
                            {localStorage.getItem('isLogged') === 'true' ?
                                < >
                                    <div className='my-account-text-drawer'>{JSON.parse(localStorage.getItem('fullUserDetails')).FirstName} {JSON.parse(localStorage.getItem('fullUserDetails')).LastName}</div>
                                    <div className='log-text-drawer' onClick={handleLogout} style={{ color: "#7C8091", cursor: "pointer" }}>Logout</div>
                                </>
                                :
                                <>
                                    <div className='my-account-text-drawer'>MY ACCOUNT</div>
                                    <div className='log-text-drawer'>
                                        <a href="/account/login" style={{ color: "#7C8091" }}>Login</a>
                                        <div className='border-line-sign-drawer' />
                                        <a href="/account/register" style={{ color: "#7C8091" }}>Signup</a>
                                    </div>
                                </>

                            }
                        </div>
                    </div>
                    <div className="profile-conatiner-right" onClick={() => {
                        setIsDrawerOpen(false)
                    }} >
                        <img alt="" src={close} />
                    </div>

                </div>
                <div className="menu-items-drawer">
                    <div className="menu-item-drawer" onClick={() => {
                        history.push("/")
                        setIsDrawerOpen(false)

                    }}>Menu</div>
                    {!isECommerceProcessPath() &&
                        pages.map((pn, index) => (
                            <>
                                {pn.children.length === 0 &&
                                    pn.ShowInMainMenu === true && (
                                        <div key={index} className='menu-item-drawer' onClick={() => {
                                            history.push(`/pages/${pn.Slug}`)
                                            setIsDrawerOpen(false)
                                        }}>{pn.PageName}</div>

                                    )}
                            </>
                        ))
                    }
                    <div className='menu-item-drawer' onClick={() => {
                        history.push("/order/track")
                        setIsDrawerOpen(false)

                    }}>Track your Order</div>
                    {localStorage.getItem('isLogged') === 'true' && <div className='menu-item-drawer' onClick={() => {
                        history.push("/order/history")
                        setIsDrawerOpen(false)

                    }}>Order history</div>}

                </div>
            </div>
        </div>
    )
}
export default Drawer



