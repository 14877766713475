import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Typography, Button, Stack, Icon, Space, InputBox, Message, Row, Col, async } from 'witmeg-ui-system';
import IMAGE_PLACEHOLDER from '../../../../../assets/Images/image-placeholder.png';
import { syncRestaurantMenuCartDetails } from '../../../../../redux/resturantWidgetSlice';
import { getCurrencyFormat, getRestaurantMenuAvailablePath, LOCAL_STORAGE_ITEMS_NAMES, setLocalStorageData } from '../../../utilities/helper';
import { deleteRestaurantMenuCartItem, updateRestaurantMenuCartItemQuantity } from '../../../utilities/restaurantMenuUtil';
import '../../../utilities/stringOverride';
import DeleteCartItemPopupConfirmationPopup from '../../common/DeleteCartItemConfirmationPopup';
import "@fontsource/inter";
import "@fontsource/plus-jakarta-sans";

const OrderSummaryTable = () => {
    let history = useHistory();
    const dispatch = useDispatch();

    const [menuPath, setMenuPath] = useState('/');

    const restaurantDataState = useSelector((state) => state.resturantData);

    const currencyFormat = getCurrencyFormat(restaurantDataState.cartDetails.currency);

    const [pageState, setPageState] = useState({
        orderTypes: restaurantDataState.cartDetails.orderTypes.filter(_type => _type !== 'table' && _type !== 'customer-choice'),
        selectedOrderType: restaurantDataState.cartDetails.defaultOrderType.replace("table", "dineIn"),
        promoCode: '',
        deleteCart: {
            visible: false,
            index: null,
        }
    });

    useEffect(() => {
        const _setData = async () => {
            const _menuPathResponse = await getRestaurantMenuAvailablePath();
            setMenuPath(_menuPathResponse);
        }
        _setData();
    }, []);

    const isCartDataAvailable = restaurantDataState.cartDetails && restaurantDataState.cartDetails.items && restaurantDataState.cartDetails.items.length > 0 ? true : false;

    const onUpdateCartItemQuantity = async (_index, _increase = true) => {
        if (!isCartDataAvailable) return;

        await updateRestaurantMenuCartItemQuantity(restaurantDataState.cartDetails, _index, _increase);
        dispatch(syncRestaurantMenuCartDetails());
    };

    const navigateToPaymentDetails = () => history.push("/payment-details");

    const onBackToMenu = () => history.push(menuPath);

    const onClickOrderTypeToggle = (_value) => {
        setPageState((prevState) => ({ ...prevState, selectedOrderType: _value, }));

        const _newCartData = { ...restaurantDataState.cartDetails, defaultOrderType: _value };
        setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA, _newCartData);

        dispatch(syncRestaurantMenuCartDetails());
    };

    const onClickApplyPromoCode = async () => {
        const _newCartData = {
            ...restaurantDataState.cartDetails,
            additions: {
                ...restaurantDataState.cartDetails.additions,
                promoCode: pageState.promoCode,
            },
        };

        await setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA, _newCartData);
        dispatch(syncRestaurantMenuCartDetails());

        Message.success('Promo code applied!');
        showAddPromoVal();
    }
    const [showPromo, setShowPromo] = useState();
    const showAddPromo = async () => {
        setShowPromo(!showPromo);
    }

    const [showPromoValue, setShowPromoValue] = useState(true);
    const showAddPromoVal = async () => {
        setShowPromoValue(!showPromoValue);
    }

    const onPromoCodeTextChange = (_text) => setPageState(prevState => ({ ...prevState, promoCode: _text }));

    const onCartItemDelete = (_index) => {
        if (!isCartDataAvailable) return;

        setPageState(prevState => ({
            ...prevState,
            deleteCart: {
                visible: true,
                index: _index,
            }
        }));
    };

    const onCartItemDeleteConfirm = () => {
        const _deleteCartItem = async (_index) => {
            await deleteRestaurantMenuCartItem(restaurantDataState.cartDetails, _index);
            dispatch(syncRestaurantMenuCartDetails());

            Message.success('Cart item was deleted!');
        }

        setPageState((prevState) => {
            _deleteCartItem(prevState.deleteCart.index);
            return {
                ...prevState,
                deleteCart: {
                    visible: false,
                    index: null,
                }
            }
        });
    }

    const onDeleteItemPopupClose = () => {
        setPageState(prevState => ({
            ...prevState,
            deleteCart: {
                visible: false,
                index: null,
            }
        }));
    }

    return (
        <div className='order-confirmation-page'>
            <Button category="icon-text" className="eco-order-confirm-back-but eco-mb-24" icon="chevron-left" text="Back to menu" iconSize={16} variant="subtle" onClick={onBackToMenu} />
            <Stack horizontalAlign="space_between" className="eco-mb-16">
                <Typography type="title" level={1} className="eco-order-confirm-title" color="gray_darkest">Order Summary</Typography>
                {
                    pageState.orderTypes.length > 1 && (
                        <Stack horizontalAlign="h_end" verticalAlign="v_center">
                            <Space className="toggle-switch-container">
                                <div className={pageState.selectedOrderType === "collection" ? "selected-switch" : "toggle-switch"} onClick={() => onClickOrderTypeToggle("collection")}><Icon name="collection" color={pageState.selectedOrderType === "collection" ? "white" : "gray"} /><span className='eco-order-summery-togg-title'>Collection</span></div>
                                <div className={pageState.selectedOrderType === "delivery" ? "selected-switch" : "toggle-switch"} onClick={() => onClickOrderTypeToggle("delivery")}><Icon name="delivery-bike" color={pageState.selectedOrderType === "delivery" ? "white" : "gray"} /><span className='eco-order-summery-togg-title'>Delivery</span></div>
                            </Space>
                        </Stack>
                    )
                }
            </Stack>
            <div className="eco-mb-16 desktop-view">
                <table className="eco-order-summary-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>PRODUCT</th>
                            <th>PRICE</th>
                            <th>QUANTITY</th>
                            <th style={{ textAlign: 'right' }}>TOTAL PRICE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isCartDataAvailable && restaurantDataState.cartDetails.items.map((item, index) => {
                                const _isUnitChartItem = (item.selection.unitChartItem && item.selection.unitChartItem.typeName) ? true : false;
                                const _hasAddons = (item.selection.addons && item.selection.addons.items && item.selection.addons.items.length > 0) ? true : false;
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div onClick={() => onCartItemDelete(index)}>
                                                <Icon name="cancel" type="filled" className="close-icon" />
                                            </div>
                                        </td>
                                        <td valign={_hasAddons ? 'top' : 'center'}>
                                            <Stack horizontalAlign="h_start" verticalAlign={_hasAddons ? 'v_start' : 'v_center'}>
                                                <div
                                                    style={{ backgroundImage: `url(${item.product.image ?? IMAGE_PLACEHOLDER})` }}
                                                    className="eco-order-summary-image"></div>
                                                <Stack direction="column" verticalAlign="v_start" className="eco-ml-32">
                                                    <Space size="middle" className={_hasAddons && "eco-mb-24"}>
                                                        <Typography type="title" level={5} pattern="bold">
                                                            {item.selection.name}
                                                        </Typography>
                                                        <Typography type="title" level={5} color="gray_lighter">
                                                            {_isUnitChartItem && `(${item.selection.unitChartItem.typeName})`}
                                                        </Typography>
                                                    </Space>

                                                    {/* Addon Items */}
                                                    {
                                                        _hasAddons && item.selection.addons.items.map((_addonItem, _addonIndex) => (
                                                            // <Stack key={_addonIndex} className="eco-mb-16" verticalAlign="v_center" horizontalAlign="space_between">
                                                            //     <div style={{ width: '100px' }}>
                                                            //         <Typography type="text">{_addonItem.name}</Typography>
                                                            //     </div>
                                                            //     <div style={{ border: '1px solid #EAE8ED', borderRadius: '6px', width: 'fit-content', display: 'flex', alignItems: 'center' }}>
                                                            //         <div style={{ padding: '5px', borderRight: '1px solid #EAE8ED', cursor: 'pointer' }}>
                                                            //             <Icon name="minus" size={16} />
                                                            //         </div>
                                                            //         <div style={{ borderRight: '1px solid #EAE8ED' }}>
                                                            //             <input className='input-order-amount' value={_addonItem.quantity} readOnly />
                                                            //         </div>
                                                            //         <div style={{ padding: '5px', cursor: 'pointer' }}>
                                                            //             <Icon name="plus" size={16} />
                                                            //         </div>
                                                            //     </div>
                                                            //     <div style={{ width: '100px' }}>
                                                            //         <Typography type="text" alignment="center" pattern="bold">
                                                            //             {currencyFormat(_addonItem.prices[pageState.selectedOrderType])}
                                                            //         </Typography>
                                                            //     </div>
                                                            // </Stack>
                                                            <Stack direction="column" verticalAlign="v_end" className="eco-mb-4">
                                                                <Typography type="text" className="eco-pr-12" style={{ display: 'flex', justifyContent: 'space-between', width: '200px', maxWidth: '100%' }}>
                                                                    <span className="eco-icon-add">
                                                                        <Icon name="plus" size={16} className="eco-mr-12" color="green" />
                                                                        {_addonItem.quantity} x {_addonItem.name}
                                                                    </span>
                                                                    <span>
                                                                        {currencyFormat(_addonItem.prices[pageState.selectedOrderType])}
                                                                    </span>
                                                                </Typography>
                                                            </Stack>
                                                        ))
                                                    }

                                                    {
                                                        item.selection.customerInstructions && item.selection.customerInstructions.trim() !== "" && (
                                                            <Stack direction="column" verticalAlign="v_end" className="eco-mb-4 eco-mt-12">
                                                                <Typography type="text" className="eco-pr-12">
                                                                    {item.selection.customerInstructions}
                                                                </Typography>
                                                            </Stack>
                                                        )
                                                    }
                                                </Stack>
                                            </Stack>
                                        </td>

                                        <td valign={_hasAddons ? 'top' : 'center'}>
                                            <Stack direction="column" verticalAlign="v_start">
                                                <Typography type="title" level={5} pattern="bold" className={_hasAddons && "eco-mb-24"}>
                                                    {currencyFormat(item.selection.prices[pageState.selectedOrderType])}
                                                </Typography>
                                                {_hasAddons && <Space size="middle" className="eco-mb-24">
                                                    <Typography type="title" level={5} pattern="bold">
                                                        {currencyFormat(item.selection.addons.subTotal[pageState.selectedOrderType].amount)}
                                                    </Typography>
                                                    <Typography type="text" color="gray_lighter"> {_hasAddons ? `(Addons)` : ''} </Typography>
                                                </Space>}
                                            </Stack>
                                        </td>

                                        <td valign={_hasAddons ? 'top' : 'center'}>
                                            <div style={{ border: '1px solid #EAE8ED', borderRadius: '6px', width: 'fit-content', display: 'flex', alignItems: 'center' }}>
                                                <div style={{ padding: '5px', borderRight: '1px solid #EAE8ED', cursor: 'pointer' }} onClick={() => onUpdateCartItemQuantity(index, false)}>
                                                    <Icon name="minus" size={16} />
                                                </div>
                                                <div style={{ borderRight: '1px solid #EAE8ED' }}>
                                                    <input className='input-order-amount' value={item.selection.quantity} readOnly />
                                                </div>
                                                <div style={{ padding: '5px', cursor: 'pointer' }} onClick={() => onUpdateCartItemQuantity(index)}>
                                                    <Icon name="plus" size={16} readOnly />
                                                </div>
                                            </div>
                                        </td>
                                        <td valign={_hasAddons ? 'top' : 'center'} style={{ textAlign: 'right' }}>
                                            <Typography type="title" level={5} pattern="bold">{currencyFormat(item.selection.total[pageState.selectedOrderType]?.amount)}</Typography>
                                        </td>
                                    </tr>
                                );
                            })
                        }

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colSpan={2}>
                                <Stack direction="column" verticalAlign="v_end">
                                    <Stack horizontalAlign="space_between" className="eco-mb-36">
                                        <Typography type="text">Sub total</Typography>
                                        <Typography type="text" pattern="bold">{currencyFormat(restaurantDataState.cartDetails.subTotal[pageState.selectedOrderType].amount, true)}</Typography>
                                    </Stack>
                                    {
                                        pageState.selectedOrderType === 'delivery' && (
                                            <Stack horizontalAlign="space_between" className="eco-mb-36">
                                                <Typography type="text">Delivery Charge:</Typography>
                                                <Typography type="text" pattern="bold">{currencyFormat(restaurantDataState.cartDetails.additions.deliveryCharges?.cost ?? 0, true)}</Typography>
                                            </Stack>
                                        )
                                    }
                                    <Stack horizontalAlign="space_between" className="eco-mb-36">
                                        <Typography type="text">VAT</Typography>
                                        <Typography type="text" pattern="bold">{currencyFormat(restaurantDataState.cartDetails.total[pageState.selectedOrderType].vatAmount, true)}</Typography>
                                    </Stack>
                                    {/* <Stack direction="column" verticalAlign="v_start" className="eco-mb-36" style={{ borderBottom: "1px solid #E9E9E9" }}>
                                        <Typography type="text" className="eco-mb-16" pattern="bold">Add Promo code</Typography>
                                        <Stack horizontalAlign="space_between" className="eco-mb-16">
                                            <InputBox onChange={e => onPromoCodeTextChange(e.target.value)} value={pageState.promoCode} type="text"/>
                                            <Button onClick={onClickApplyPromoCode} variant="outline">Apply</Button>
                                        </Stack>
                                    </Stack> */}
                                    <Stack horizontalAlign="space_between" className="eco-mb-36">
                                        <Typography type="text">Total:</Typography>
                                        <Typography type="text" pattern="bold">
                                            <span style={{ backgroundColor: '#F4F4F4', padding: '10px', borderRadius: '8px' }}>{currencyFormat(restaurantDataState.cartDetails.total[pageState.selectedOrderType].amount, true)}</span>
                                        </Typography>
                                    </Stack>
                                    <Button
                                        category="icon-text"
                                        icon="cash"
                                        text="Proceed to Payment"
                                        variant="primary"
                                        color="white"
                                        onClick={() => navigateToPaymentDetails()}
                                        className="proceed-payment-button" />
                                </Stack>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="eco-mb-16 mobile-view">
                {/* <div style={{ backgroundColor: '#F0F1F4', borderRadius: '8px 8px 0px 0px', padding: '15px 20px',marginBottom:'40px' }}>
                    <Stack horizontalAlign="space_between">
                        <Typography type="text">Order Summary</Typography>
                        <Stack horizontalAlign="space_between" verticalAlign="v_center" className="toggle-switch-container">
                            <div className={pageState.selectedOrderType === "delivery" ? "selected-switch" : "toggle-switch"} onClick={() => onClickOrderTypeToggle("delivery")}><Icon name="collection" color={pageState.selectedOrderType === "delivery" ? "white" : "gray"} /></div>
                            <div className={pageState.selectedOrderType === "collection" ? "selected-switch" : "toggle-switch"} onClick={() => onClickOrderTypeToggle("collection")}><Icon name="delivery-bike" color={pageState.selectedOrderType === "collection" ? "white" : "gray"} /></div>
                        </Stack>
                    </Stack>
                </div> */}
                <div>
                    {/* <Typography type="title" level={5} pattern="bold" className="eco-mb-40">PRODUCTS</Typography>                     */}
                    {
                        isCartDataAvailable && restaurantDataState.cartDetails.items.map((item, index) => {
                            const _isUnitChartItem = (item.selection.unitChartItem && item.selection.unitChartItem.typeName) ? true : false;
                            const _hasAddons = (item.selection.addons && item.selection.addons.items && item.selection.addons.items.length > 0) ? true : false;
                            return (
                                <Row key={index} justify="space-between" className="eco-mb-12" style={{ border: '1px solid #F2F0F5', borderRadius: '4px', padding: '12px 8px' }}>
                                    <Col lg={3} md={3} sm={4} xs={6} >
                                        <Typography
                                            type="title"
                                            level={5}
                                            pattern="bold"
                                            style={{ backgroundImage: `url(${item.product.image ?? IMAGE_PLACEHOLDER})` }}
                                            className="eco-order-summary-image"></Typography>
                                    </Col>
                                    <Col lg={21} md={21} sm={20} xs={18} className="eco-order-summery-detail-col">
                                        <Stack verticalAlign="baseline" horizontalAlign="space_between" >
                                            <Typography type="title" level={5} pattern="bold" className="truncate-text-line-2">
                                                {item.selection.name} {_isUnitChartItem && `(${item.selection.unitChartItem.typeName})`}
                                            </Typography>
                                        </Stack>
                                        <Stack verticalAlign="v_start">
                                            <Col span={8} className="eco-product-add">
                                                <div className='eco-product-qty-action-button' onClick={() => onUpdateCartItemQuantity(index, false)}>
                                                    <Icon name="minus" size={16} />
                                                </div>
                                                <div className='eco-product-qty'>
                                                    <input className='input-order-amount' value={item.selection.quantity} readOnly />
                                                </div>
                                                <div className='eco-product-qty-action-button' onClick={() => onUpdateCartItemQuantity(index)}>
                                                    <Icon name="plus" size={16} readOnly />
                                                </div>
                                            </Col>
                                            <Col span={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div className="eco-product-delete-button" onClick={() => onCartItemDelete(index)}>
                                                    <Icon name="delete" size={16} />
                                                </div>
                                            </Col>
                                            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
                                                <Typography type="title" level={5} pattern="bold" className={"eco-mb-12"}>
                                                    {currencyFormat(item.selection.total[pageState.selectedOrderType].amount)}
                                                </Typography>
                                            </Col>
                                        </Stack>

                                        {/* Addon Items */}
                                        {
                                            _hasAddons && item.selection.addons.items.map((_addonItem, _addonIndex) => (
                                                <Stack direction="column" verticalAlign="v_end" className="eco-mb-4 eco-mt-12">
                                                    <Typography type="text" className="eco-pr-12" style={{ display: 'flex', justifyContent: 'space-between', width: '200px', maxWidth: '100%' }}>
                                                        <span className="eco-icon-add">
                                                            <Icon name="plus" size={16} className="eco-mr-12" color="green" />
                                                            {_addonItem.quantity} x {_addonItem.name}
                                                        </span>
                                                        <span>
                                                            {currencyFormat(_addonItem.prices[pageState.selectedOrderType])}
                                                        </span>
                                                    </Typography>
                                                </Stack>
                                            ))
                                        }

                                        {
                                            item.selection.customerInstructions && item.selection.customerInstructions.trim() !== "" && (
                                                <Stack direction="column" verticalAlign="v_end" className="eco-mb-4 eco-mt-12">
                                                    <Typography type="text" className="eco-pr-12">
                                                        {item.selection.customerInstructions}
                                                    </Typography>
                                                </Stack>
                                            )
                                        }
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    <Stack horizontalAlign="space_between" className="eco-mb-24" style={{ marginTop: '30px' }}>
                        <Typography type="title" level={5}>Sub total</Typography>
                        <Typography type="title" level={5} pattern="bold">{currencyFormat(restaurantDataState.cartDetails.subTotal[pageState.selectedOrderType].amount, true)}</Typography>
                    </Stack>
                    {
                        pageState.selectedOrderType === 'delivery' && (
                            <Stack horizontalAlign="space_between" className="eco-mb-24">
                                <Typography type="title" level={5}>Delivery charge</Typography>
                                <Typography type="title" level={5} pattern="bold">{currencyFormat(restaurantDataState.cartDetails.additions.deliveryCharges?.cost ?? 0, true)}</Typography>
                            </Stack>
                        )
                    }

                    <Stack horizontalAlign="space_between" className="eco-mb-24">
                        <Typography type="title" level={5}>VAT</Typography>
                        <Typography type="title" level={5} pattern="bold">{currencyFormat(restaurantDataState.cartDetails.total[pageState.selectedOrderType].vatAmount, true)}</Typography>
                    </Stack>
                    {/* <Stack direction="column" verticalAlign="v_start" className="eco-mb-36" style={{ borderBottom: "1px solid #E9E9E9" }}>
                        <Typography type="text" className="eco-mb-16" pattern="bold">Add Promo code</Typography>
                        <Stack horizontalAlign="space_between" className="eco-mb-16">
                            <InputBox type="text" />
                            <Button variant="outline">Apply</Button>
                        </Stack>
                    </Stack> */}
                    <Stack horizontalAlign="space_between" className="eco-mb-24">
                        <Typography type="title" level={5}>Total</Typography>
                        <Typography type="text" className="eco-total-amount" >
                            {currencyFormat(restaurantDataState.cartDetails.total[pageState.selectedOrderType].amount, true)}
                        </Typography>
                    </Stack>
                    <Button
                        fullWidth
                        className="proceed-payment-button"
                        category="icon-text"
                        icon="cash"
                        text="Proceed to Payment"
                        variant="primary"
                        color="white"
                        onClick={() => navigateToPaymentDetails()}
                        style={{ background: '#363B41' }} />
                </div>
            </div>
            <DeleteCartItemPopupConfirmationPopup
                visibility={pageState.deleteCart.visible}
                onPopupClose={onDeleteItemPopupClose}
                onDeleteConfirm={onCartItemDeleteConfirm} />
        </div>
    )
}

export default OrderSummaryTable