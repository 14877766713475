import React, { useState } from "react";
import Navbar from "./components/Navbar/Navbar";
import Drawer from "./components/Drawer/Drawer";

const HospitalityPageWrapper = ({ children }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    return (
        <>
            <div className="page-wrapper">
                <Navbar setIsDrawerOpen={setIsDrawerOpen} />
                {isDrawerOpen && <Drawer setIsDrawerOpen={setIsDrawerOpen} />}
                {children}
            </div>

        </>
    );
}

export default HospitalityPageWrapper;