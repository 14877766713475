import React from 'react';
import { Redirect } from 'react-router-dom'
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Divider, Avatar } from 'antd';
import AccountLeftMenu from "./account-left-menu";
import {
  CodepenOutlined, UserOutlined, HeartFilled, EnvironmentFilled, CreditCardFilled, LogoutOutlined
} from '@ant-design/icons';
import config from 'react-global-configuration';
import AccountMemberMartFury from "../themes/martfury/account-member";
import AccountMemberApparel from "../themes/apparel/account-member";
class Application extends React.Component {
  constructor(props) {
    super(props);
    this.loggedIn = sessionStorage.getItem('loggedin') === 'true';

  }
  onLogout = () => {
    // sessionStorage.setItem('loggedin', false);
    // sessionStorage.setItem('username', "");
    // localStorage.setItem('fullUserDetails', "");
    // sessionStorage.setItem('deliverycost', '');
    // window.location.reload();
  };
  componentDidMount() {
    document.title = "My Accounnt | " + config.get('companyPageTitle')
  }


  render() {

    if (!this.loggedIn) {
      return <Redirect to='/account/login' />;
    }

    return (
      <>

        <div className='account-pages-wrapper'>
          <Row>

            <Col sm={12} >
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/account/member">
                  Your Account
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>

            <Col sm={3}>
              <AccountLeftMenu />
            </Col>

            <Col sm={9}>

              {(() => {

                switch (config.get('Theme')) {
                  case 'Apparel':
                    return (<AccountMemberApparel onLogout={this.onLogout} />)
                  case 'Martfury':
                    return (<AccountMemberMartFury onLogout={this.onLogout} />)
                  default:
                    return (

                      <div className="fade alert alert-light show">
                        <Row gutter={16} className="justify-content-md-center">


                          <Divider orientation="left"><h5>YOUR ACCOUNT {sessionStorage.getItem('userfirstname').toUpperCase() + " " + sessionStorage.getItem('userlastname').toUpperCase()} <span className="homeMessage">( <a href="/account/login" onClick={this.onLogout.bind()}>Log Out</a> )</span></h5></Divider>


                          <Col sm={6} className="mt-lg-5">
                            <Row>
                              <Col sm={2}>
                                <Avatar size={50} style={{ backgroundColor: '#caa622' }} icon={<UserOutlined />} />
                              </Col>
                              <Col ><a href="/account/my-info"><h5>Your Info</h5><p>Update your details.</p></a></Col>

                            </Row>
                          </Col>

                          <Col sm={6} className="mt-lg-5">
                            <Row>
                              <Col sm={2}>
                                <Avatar size={50} style={{ backgroundColor: '#caa622' }} icon={<CodepenOutlined />} />
                              </Col>
                              <Col><a href="/account/order-history"><h5>Your Orders</h5><p>Check your order status or see past orders.</p></a></Col>
                            </Row>
                          </Col>

                          <Col sm={6} className="mt-lg-5">
                            <Row>
                              <Col sm={2}>
                                <Avatar size={50} style={{ backgroundColor: '#caa622' }} icon={<EnvironmentFilled />} />
                              </Col>
                              <Col ><a href="/account/addresses"><h5>Addresses</h5><p>Manage your addresses.</p></a></Col>

                            </Row>
                          </Col>


                          <Col sm={6} className="mt-lg-5">
                            <Row>
                              <Col sm={2}>
                                <Avatar size={50} style={{ backgroundColor: '#caa622' }} icon={<HeartFilled />} />
                              </Col>
                              <Col><a href="/account/my-wishlists"><h5>Your Wishlist</h5><p>View, modify and shop from your lists.</p></a></Col>
                            </Row>
                          </Col>

                          <Col sm={6} className="mt-lg-5">
                            <Row>
                              <Col sm={2}>
                                <Avatar size={50} style={{ backgroundColor: '#caa622' }} icon={<CreditCardFilled />} />
                              </Col>
                              <Col ><a href="/account/payment"><h5>Payment Settings</h5><p>Manage your payment settings.</p></a></Col>

                            </Row>
                          </Col>

                          <Col sm={6} className="mt-lg-5">
                            <Row>
                              <Col sm={2}>
                                <Avatar size={50} style={{ backgroundColor: '#caa622' }} icon={<LogoutOutlined />} />
                              </Col>
                              <Col><a href="/account/login" onClick={this.onLogout.bind()}><h5>Logout</h5><p>Logout from our system.</p></a></Col>
                            </Row>
                          </Col>
                        </Row>




                      </div>

                    )
                }

              })()}

            </Col>



          </Row>
        </div>



      </>
    )
  }
}

export default Application;