export const commonErrorHandler = (
    error,
    defaultErrorMsg = "Sorry, there was a problem. Please try again."
) => {
    const errorMsg = error?.message?.includes("Network Error")
        ? "Network Error. Please check your connection."
        : defaultErrorMsg;

    return { errorMsg };
};

export const APIError = (
    customErrMsg = "Error occurred. Please try again.",
    orgError = ""
) => {
    const error = new Error(orgError || customErrMsg);
    error.customErrType = "APIERROR";
    error.customErrMsg = customErrMsg;

    return error;
};

export const errorLogger = (error, options = {}) =>
    process.env.NODE_ENV === "development" && console.error(new Error(error), options);
