import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router'
import { Button, Stack, Typography } from 'witmeg-ui-system'

import "@fontsource/inter";
import OrderConfirmationForm from './OrderConfirmationForm';
import AddressSelection from './AddressSelection'

const OrderConfirmation = () => {

    let history = useHistory();
    const settings = useSelector((state) => state.companyData.settings);
    const fullUserDetails = useSelector((state) => state.retailData.fullUserDetails)

    const onBackToMenu = () => {
        // history.push(menuPath);
        history.goBack();
        setTimeout(() => { window.location.reload() }, 500)
    }



    return (
        <div className='order-confirmation-page'>
            <Button className="eco-order-confirm-back-but eco-mb-24" category="icon-text" icon="chevron-left" text={settings.websiteType === "Restaurant" ? "Back to menu" : "Back"} iconSize={16} variant="subtle" onClick={onBackToMenu} />
            {fullUserDetails ?
                <AddressSelection userDetails={fullUserDetails} />
                : <OrderConfirmationForm />}
        </div>
    )
}

export default OrderConfirmation