import React from 'react';
import "./customTabs.css";
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedRestaurantMainMenu, fetchAllRestaurantMenuData } from '../../../../../redux/resturantWidgetSlice';
import '../../../utilities/stringOverride';
import "@fontsource/jost";

const CustomTabs = ({ children, isPopup = false }) => {
  const dispatch = useDispatch();

  const { menuData: { storage: { mainMenus }, selectedMainMenuID, uiCustomizations }, defualtSelectedLocation, organizationID } = useSelector((state) => state.resturantData);

  const handleActiveTab = (_menuID) => {
    dispatch(changeSelectedRestaurantMainMenu({ mainMenuID: _menuID }));
    dispatch(fetchAllRestaurantMenuData({
      mainMenuID: _menuID,
      CloudLocationID: defualtSelectedLocation.CloudLocationID,
      OrganizationID: organizationID,
    }));
  }
  return (
    <>
      <div className='tab-headers'>
        {
          mainMenus && mainMenus.length > 0 && mainMenus.map((_item) =>
          (
            <div
              key={_item.menuID}
              onClick={() => handleActiveTab(_item.menuID)}
              className={_item.menuID === selectedMainMenuID ? 'tab-item selected-tab-item' : 'tab-item'}>
              <span style={uiCustomizations.tabs.title}>{_item.menuName.toCapitalFirst()}</span>
            </div>
          )
          )
        }
      </div>
      <div className={isPopup ? 'tab-content-container tab-popup' : 'tab-content-container'}>
        {children}
      </div>
    </>
  );
}

export default CustomTabs;