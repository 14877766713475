const RequiredField = (value) => {
    return value && value !== '' ? false : true;
};

const IsEmail = (value) => {
    const mailFormat = /^[a-z]+[\w._\-]+@{1}[a-z]+[a-z\-.]*[.]{1}[a-z]{2,}$/;
    return value.match(mailFormat) ? false : true;
}

const IsPhoneNo = (value) => {
    const phoneNoFormat = /^\+?[0-9]+$/i;
    return value.match(phoneNoFormat) ? false : true;
}

const IsNumberOnly = (value) => {
    const numberFormat = /^[0-9]*$/;
    return numberFormat.test(String(value)) ? false : true;
};

const IsTextOnly = (value) => {
    const textFormat = /^[a-z]*$/i;
    return value.match(textFormat) ? false : true;
}

const ValidLength = ({ textLength }) => (value) => {
    return String(value).length === textLength ? false : true;
};

const IsPostcode = (value) => {
    const textFormat = /[^ a-z0-9-]/i;
    return value.match(textFormat) ? true : false;
}

const HasSelected = (value) => {
    return (value && value.value !== "") ? false : true;
}

const DataValidation = (type, value, additional) => {
    switch (type) {
        case DATA_VALIDATION_TYPES.REQUIRED_FIELD: {
            const hasError = RequiredField(value);
            const errorType = hasError ? type : null;
            return { hasError, errorType };
        };
        case DATA_VALIDATION_TYPES.IS_NUMBER_ONLY: {
            const hasError = IsNumberOnly(value);
            const errorType = hasError ? type : null;
            return { hasError, errorType };
        };
        case DATA_VALIDATION_TYPES.IS_EMAIL: {
            const hasError = IsEmail(value);
            const errorType = hasError ? type : null;
            return { hasError, errorType };
        };
        case DATA_VALIDATION_TYPES.IS_TEXT_ONLY: {
            const hasError = IsTextOnly(value);
            const errorType = hasError ? type : null;
            return { hasError, errorType };
        };
        case DATA_VALIDATION_TYPES.VALID_LENGTH: {
            const hasError = DataValidation(value, additional.length);
            const errorType = hasError ? type : null;
            return { hasError, errorType };
        };
        case DATA_VALIDATION_TYPES.HAS_SELECTED: {
            const hasError = HasSelected(value);
            const errorType = hasError ? type : null;
            return { hasError, errorType };
        };
        case DATA_VALIDATION_TYPES.IS_POSTCODE: {
            const hasError = IsPostcode(value);
            const errorType = hasError ? type : null;
            return { hasError, errorType };
        }
        case DATA_VALIDATION_TYPES.IS_PHONE_NO: {
            const hasError = IsPhoneNo(value);
            const errorType = hasError ? type : null;
            return { hasError, errorType };
        }
    }
}

export const DATA_VALIDATION_TYPES = {
    REQUIRED_FIELD: 'REQUIRED_FIELD',
    IS_NUMBER_ONLY: 'IS_NUMBER_ONLY',
    IS_EMAIL: 'IS_EMAIL',
    IS_TEXT_ONLY: 'IS_TEXT_ONLY',
    VALID_LENGTH: 'VALID_LENGTH',
    HAS_SELECTED: 'HAS_SELECTED',
    IS_POSTCODE: 'IS_POSTCODE',
    IS_PHONE_NO: 'IS_PHONE_NO',
}

export default DataValidation;