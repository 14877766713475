import { DecreaseQuantityButton, IncreaseQuantityButton, QuantityContainer, QuantityTextbox } from "./StyledComponents"
import { Accordion, Alert, Button, Checkbox, Icon, Message, Popup, Stack, TextArea, Typography } from 'witmeg-ui-system';


const QuantitySelector = ({quantity}) => {
    return <QuantityContainer>
        <DecreaseQuantityButton>
            <Icon name="minus" size={16} />
        </DecreaseQuantityButton>
        <QuantityTextbox value={quantity}/>
        <IncreaseQuantityButton>
            <Icon name="plus" size={16} />
        </IncreaseQuantityButton>
    </QuantityContainer>
}

export default QuantitySelector