import QuantitySelector from "./QuantitySelector"
import { ModifierOptionContainer } from "./StyledComponents"

const MainModifierOption = ({ title, quantity, unitPrice }) => {
    return <ModifierOptionContainer>
        <span>{title}</span>
        <QuantitySelector quantity={quantity} />
        <span>{unitPrice}</span>
    </ModifierOptionContainer>
}
export default MainModifierOption