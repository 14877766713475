import React from 'react';
import { Button, Popup, Stack, Typography } from 'witmeg-ui-system';

const TimeoutPopup = ({ onOkConfirm }) => {

    return (
        <Popup
            className="delete-cartitem-popup-close"
            modalVisible={true}
            modalTitle=""
            mask={true}
            centered
            closable={false}>
            <Typography type="text" pattern="bold" className="eco-mh-20">
                <h2>Your session has timed out.</h2>
                <div>To continue ordering, please scan the QR code again to refresh your session and begin a new order.</div>
            </Typography>
            <Stack className="eco-mt-28 eco-m-8">
                <Button variant="primary" className="eco-mr-20 eco-pl-16 eco-pr-16" onClick={onOkConfirm}>
                    OK
                </Button>
            </Stack>
        </Popup>
    );
}

export default TimeoutPopup;