import React from 'react'
import { useHistory } from 'react-router';
import { Button, Icon, Stack, Typography } from 'witmeg-ui-system'
import "./HospitalityDefaultOrderPage.scss"
import failedIc from "../../assets/failed.svg"
const HospitalityDefaultOrderFailedPage = () => {

    const history = useHistory();

    const onClickBackToMenu = () => history.push("/");

    return (
        <div className='Order-success-form-deafult'>
            <>
                <img alt='' src={failedIc} className='sucessIc' />
                <div className="main-message-text">Your order could not be processed</div>
                <div type="text" className="card-payment-desc-text">
                    Please try again, or contact support.
                </div>
                <div className='btn-conatiner'>
                    <div onClick={onClickBackToMenu} className='menu-btn'>Back to Menu</div>
                </div>
            </>
        </div>
    )
}

export default HospitalityDefaultOrderFailedPage