import _ from 'lodash';


export const getOrderType = (orderType) => {
    let typeOfOrder = ""
    let tableNumber = ""
    const pathName = window.location.pathname
    const orderTypeString = pathName.split("/")[4]
    const cloudLocationId = pathName.split("/")[3]
    const organizationId = pathName.split("/")[2]

    if (orderTypeString) {
        if (orderTypeString.includes("yHBTzJCEiV50QAOhSEFWdiDY8ScBDceP+65d6dfd4452ece9397f01637"))
            typeOfOrder = "takeaway"
        if (orderTypeString.includes("yHBTzJCEiV50QAOhSEFWdiDY8ScBDceP+65d6e020452ece9397f01639"))
            typeOfOrder = "delivery"
        if (orderTypeString.includes("W44Ztd7G49v41GA9cwKQtZcDTzzXAI6C+65d6df91b4486d9ff2f5ee52")) {
            typeOfOrder = "table"
            tableNumber = orderTypeString.split("+")[2]
        }
    }
    else {
        typeOfOrder = orderType
    }
    return { orderType: typeOfOrder, tableNumber, cloudLocationId, organizationId }
}

export const LOCAL_STORAGE_ITEMS_NAMES = {
    ECOM_CART_DATA: '__eco_rp_eucd',
    ECOM_CUSTOMER_DETAILS: '__eco_rp_ecd',
    ECOM_RESTAURANT_MENU_CURRENCY: '__eco_rp_rmc',
    ECOM_RESTAURANT_MENU_ORDER_TYPES: '__eco_rmot',
    ECOM_RESTAURANT_ALL_MENU_DATA: '__eco_ramd',
    ECOM_RESTAURANT_CART_DATA: '__eco_rcd',
    ECOM_RESTAURANT_CART_ORDER_ID: '__eco_rc_oid',
    ECOM_RESTAURANT_ORDER: '__eco_rc_ord',
    ECOM_RESTAURANT_USER_ID: '__eco_rc_cui',
    ECOM_RESTAURANT_DATA: '__eco_rd',
    ECOM_RESTAURANT_TRACK_ORDER: '__eco_rto'
};

export const getLocalStorageData = async (propertyName, isJSON = true) => {
    let propertyValue = localStorage.getItem(propertyName);

    if (!propertyValue) return null;

    if (isJSON) return JSON.parse(propertyValue);
    else return propertyValue;
};

export const setLocalStorageData = async (propertyName, propertyValue, isJSON = true) => {
    const data = isJSON ? JSON.stringify(propertyValue) : propertyValue;
    await localStorage.setItem(propertyName, data);
}

export const getCurrencyFormat = (_type = 'GBP') => (_value, _withSpace = false) => {
    const _formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: _type.toUpperCase() });
    const _newValue = _formatter.format(_value);

    if (_withSpace) return _formatter.format(_value).replace(/^(\D+)/, '$1 ').replace(/\s+/, ' ');

    return _newValue;
};

export const isECommerceProcessPath = () => {
    const _validPaths = ["checkout", 'order-summary', 'payment-details', 'order-success'];

    const _path = window.location.pathname.split('/');

    if (!_path[1] || _path[1] === "" || !_validPaths.includes(_path[1])) return false;

    return true;
}