import { Accordion, Alert, Button, Checkbox, Icon, Message, Popup, Stack, TextArea, Typography } from 'witmeg-ui-system';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import MainModifierOption from './MainModifiedOption';
import { ItemContainer, ModifierOptionContainer, ModifiersWrapper } from './StyledComponents';


const ItemPopup = ({ showPopup, onPopupClose, itemId }) => {
    const { menuData: { storage: { data } } } = useSelector((state) => state.resturantData);

    const [itemModifierOptions, setItemmodifierOptions] = useState(null)

    useEffect(() => {
        const { Items, ModifierGroups } = data

        const selectedItem = _.find(Items, { MenuItemID: itemId });
        const modifierOptions = []
        console.log(selectedItem, selectedItem.ModifierGroupRules, selectedItem.ModifierGroupRules.length)
        if (selectedItem && selectedItem.ModifierGroupRules && selectedItem.ModifierGroupRules.IDs && selectedItem.ModifierGroupRules.IDs.length > 1) {
            selectedItem.ModifierGroupRules.IDs.forEach(rule => {
                console.log(rule)
                modifierOptions.push(_.find(ModifierGroups, { ModifierGroupID: rule }))
            }
            )
        }
        console.log(modifierOptions)
        setItemmodifierOptions(modifierOptions)

    }, [data])
    console.log(data, itemId)
    return <Popup width={460} className="eco-order-item-popup" modalVisible={showPopup} modalTitle="Add Item" mask={true} onCancel={onPopupClose}>
        <ItemContainer>
            <Accordion
                theme='default'
                type='content_only'
                title={"Choose your item"}
                expanded={true}
            >
                <ModifiersWrapper>
                    {itemModifierOptions && itemModifierOptions.map(modifierOption => <MainModifierOption title={modifierOption.Title.en} unitPrice="450" quantity={2} />)}
                </ModifiersWrapper>
            </Accordion>
        </ItemContainer>
    </Popup>
}

export default ItemPopup