import React, { useState } from "react";
import DataValidation, { DATA_VALIDATION_TYPES } from "../../utilities/helper/dataValidation";
import { useHistory } from 'react-router'
import { Button, Checkbox, Col, InputBox, Label, Row, Space, Stack, Typography } from 'witmeg-ui-system'
import { LOCAL_STORAGE_ITEMS_NAMES, setLocalStorageData } from "../../utilities/helper/helper";


const OrderConfirmationPage = () => {
    const userDetails = localStorage.getItem('fullUserDetails')
    let parsedUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'))
    const address = userDetails ? parsedUserDetails.Addresses.find((address) => address.AddressType === "Billing") : null
    const customerDetailsInitialState = {
        firstName: {
            value: userDetails ? parsedUserDetails.FirstName : "",
            hasError: false,
            errorType: null,
            errorMessage: null,
            validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD, DATA_VALIDATION_TYPES.IS_TEXT_ONLY],
        },
        lastName: {
            value: userDetails ? parsedUserDetails.LastName : "",
            hasError: false,
            errorType: null,
            errorMessage: null,
            validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD, DATA_VALIDATION_TYPES.IS_TEXT_ONLY],
        },
        mobileNo: {
            value: address ? address.Phone : '',
            hasError: false,
            errorType: null,
            errorMessage: null,
            errorMessage: null,
            validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD, DATA_VALIDATION_TYPES.IS_PHONE_NO],
        },
        emailAddress: {
            value: userDetails ? parsedUserDetails.Email : "",
            hasError: false,
            errorType: null,
            errorMessage: null,
            validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD, DATA_VALIDATION_TYPES.IS_EMAIL],
        },
        customerAddress: {
            line1: {
                value: address ? address.AddressLine1 : '',
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD],
            },
            line2: {
                value: address ? address.AddressLine2 : '',
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [],
            },
            state: {
                value: address ? address.StateProvinceRegion : '',
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD],
            },
            city: {
                value: address ? address.City : '',
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD],
            },
            postcode: {
                value: address ? address.PostZipCode : '',
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD, DATA_VALIDATION_TYPES.IS_POSTCODE],
            },
            country: {
                value: 'United Kingdom',
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [],
            },
        },
        deliveryAddress: {
            line1: {
                value: '',
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD],
            },
            line2: {
                value: '',
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [],
            },
            state: {
                value: '',
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD],
            },
            city: {
                value: '',
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD],
            },
            postcode: {
                value: '',
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD, DATA_VALIDATION_TYPES.IS_POSTCODE],
            },
            country: {
                value: 'United Kingdom',
                hasError: false,
                errorType: null,
                errorMessage: null,
                // validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD],
                validations: [],
            },
        },
        isDeliveryAddressSame: true,
    };
    let history = useHistory();
    const [customerDetails, setCustomerDetails] = useState(customerDetailsInitialState);
    const onFormDataChanged = (property, value) => {
        setCustomerDetails((prevState) => {
            const [key, subKey] = property.split('.');

            if (subKey) {
                return {
                    ...prevState,
                    [key]: {
                        ...prevState[key],
                        [subKey]: {
                            ...prevState[key][subKey],
                            value,
                        },
                    },
                };
            }

            return {
                ...prevState,
                [property]: {
                    ...prevState[property],
                    value,
                },
            };
        });
    };
    const toggleIsDeliveryAddressDifferent = () => {
        setCustomerDetails(prevState => ({
            ...prevState,
            isDeliveryAddressSame: !prevState.isDeliveryAddressSame,
        }));
    };
    const validateFieldSet = (fields) => {
        let pageHasError = false;
        const newFields = { ...fields };

        for (const key in fields) {
            if (!fields.hasOwnProperty(key)) continue;

            let validationRes = { hasError: false, errorType: null };

            for (const validation of fields[key].validations) {
                if (validationRes.hasError) break;

                validationRes = DataValidation(validation, fields[key].value, fields[key].additional ?? null);
            }

            if (validationRes.hasError) pageHasError = true;

            newFields[key] = {
                ...newFields[key],
                ...validationRes,
            };
        }

        return {
            fields: newFields,
            hasError: pageHasError,
        };
    };

    const validateAllFormData = () => {
        return new Promise((resolve) => {
            let pageError = false;

            setCustomerDetails(prevState => {
                const { customerAddress, deliveryAddress, isDeliveryAddressSame, ...basicFields } = prevState;
                const newBasic = validateFieldSet(basicFields);
                const newCustomerAddress = validateFieldSet(customerAddress);
                const newDeliveryAddress = isDeliveryAddressSame
                    ? { fields: customerAddress, hasError: false }
                    : validateFieldSet(deliveryAddress);

                const pageHasError = newBasic.hasError || newCustomerAddress.hasError || newDeliveryAddress.hasError;
                pageError = pageHasError;

                if (!pageHasError) {
                    const customerFormData = {
                        ID: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID),
                        Name: {
                            DisplayName: basicFields.firstName.value,
                            FirstName: basicFields.firstName.value,
                            LastName: basicFields.lastName.value,
                        },
                        Email: basicFields.emailAddress.value,
                        Contact: {
                            Number: basicFields.mobileNo.value,
                            PinCode: "+44",
                            CountryISO2: "GB"
                        },
                        BillingAddress: {
                            Id: "",
                            AddressType: "",
                            AddressLine1: customerAddress.line1.value,
                            AddressLine2: customerAddress.line2?.value || '',
                            StateProvinceRegion: customerAddress.state.value,
                            PostZipCode: customerAddress.postcode.value,
                            Country: customerAddress.country.value,
                            CountryCode: "",
                            AddressLabel: "",
                            Longitude: 0,
                            Latitude: 0,
                            Additional: {
                                Instruction: "",
                                InteractionType: ""
                            }
                        },
                        DeliveryAddress: {
                            Id: "",
                            AddressType: "",
                            AddressLine1: isDeliveryAddressSame ? customerAddress.line1.value : deliveryAddress.line1?.value,
                            AddressLine2: isDeliveryAddressSame ? customerAddress.line2.value || '' : deliveryAddress.line2?.value || '',
                            StateProvinceRegion: isDeliveryAddressSame ? customerAddress.state.value : deliveryAddress.state?.value,
                            PostZipCode: isDeliveryAddressSame ? customerAddress.postcode.value : deliveryAddress.postcode?.value,
                            Country: isDeliveryAddressSame ? customerAddress.country.value : deliveryAddress.country?.value,
                            CountryCode: "",
                            AddressLabel: "",
                            Longitude: 0,
                            Latitude: 0,
                            Additional: {
                                Instruction: "",
                                InteractionType: ""
                            }
                        },
                    };
                    let data = {
                        "UserID": localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID),
                        "CompanyName": "",
                        "UserName": basicFields.firstName.value.toLowerCase(),
                        "FirstName": basicFields.firstName.value,
                        "LastName": basicFields.lastName.value,
                        "Addresses": [
                            {
                                "AddressType": "Billing",
                                "HouseNo": "",
                                "AddressLine1": customerAddress.line1.value,
                                "AddressLine2": customerAddress.line2?.value || "",
                                "City": customerAddress.city.value,
                                "StateProvinceRegion": customerAddress.state.value,
                                "PostZipCode": customerAddress.postcode.value,
                                "Country": customerAddress.country.value,
                                "Phone": basicFields.mobileNo.value,
                                "Fax": "",
                                "Email": basicFields.emailAddress.value,
                                "CreatedDate": "",
                                "LastModifiedDate": "",
                                "IsDefault": false,
                                "IsActive": false,
                                "TelephoneCode": "+44",
                                "AddressLabel": ""
                            },
                            {
                                "AddressType": "Delivery",
                                "HouseNo": "",
                                "AddressLine1": isDeliveryAddressSame ? customerAddress.line1.value : deliveryAddress.line1?.value,
                                "AddressLine2": isDeliveryAddressSame ? (customerAddress.line2?.value || "") : (deliveryAddress.line2?.value || ""),
                                "City": customerAddress.city.value,
                                "StateProvinceRegion": isDeliveryAddressSame ? customerAddress.state.value : deliveryAddress.state?.value,
                                "PostZipCode": isDeliveryAddressSame ? customerAddress.postcode.value : deliveryAddress.postcode?.value,
                                "Country": isDeliveryAddressSame ? customerAddress.country.value : deliveryAddress.country?.value,
                                "Phone": basicFields.mobileNo.value,
                                "Fax": "",
                                "Email": basicFields.emailAddress.value,
                                "CreatedDate": "",
                                "LastModifiedDate": "",
                                "IsDefault": false,
                                "IsActive": false,
                                "TelephoneCode": "+44",
                                "AddressLabel": ""
                            }
                        ],
                        "Email": basicFields.emailAddress.value,
                    }
                    localStorage.setItem("fullUserDetails", JSON.stringify(data))


                    setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS, customerFormData);
                }
                return {
                    ...prevState,
                    ...newBasic.fields,
                    customerAddress: newCustomerAddress.fields,
                    deliveryAddress: newDeliveryAddress.fields,
                };
            });

            setTimeout(() => resolve(pageError), 0);
        });
    };



    return (
        <div className="Order-confirmation">
            <Typography type="title" level={1} className="eco-mb-32 eco-order-confirm-title" color="gray_darkest">Order Confirmation</Typography>
            <div className="eco-mb-16">

                <div className="order-form">
                    <div className="form-header">Contact Detail</div>
                    <div className="order-form-container order-det ">
                        <Row className="eco-mb-24" gutter={[16, 16]}>
                            <Col lg={12} sm={24} xs={24}>
                                <Row className="form-row">
                                    <Col span={24}>
                                        <div className="form-lbl-wrapper"><Label required>First Name</Label></div>
                                    </Col>
                                    <Col span={24}>
                                        <InputBox
                                            onChange={(e) => onFormDataChanged('firstName', e.target.value)}
                                            type="text"
                                            value={customerDetails.firstName.value}
                                            error={customerDetails.firstName.hasError}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12} sm={24} xs={24}>
                                <Row className="form-row">
                                    <Col span={24}>
                                        <div className="form-lbl-wrapper"><Label required>Last Name</Label></div>
                                    </Col>
                                    <Col span={24}>
                                        <InputBox onChange={(e) => onFormDataChanged('lastName', e.target.value)} type="text" value={customerDetails.lastName.value} error={customerDetails.lastName.hasError} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="eco-mb-24" gutter={[16, 16]}>
                            <Col lg={12} sm={24} xs={24}>
                                <Row className="form-row">
                                    <Col span={24}>
                                        <div className="form-lbl-wrapper"><Label required>Mobile Number</Label></div>
                                    </Col>
                                    <Col span={24}>
                                        <InputBox onChange={(e) => onFormDataChanged('mobileNo', e.target.value)} type="text" value={customerDetails.mobileNo.value} error={customerDetails.mobileNo.hasError} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12} sm={24} xs={24}>
                                <Row className="form-row">
                                    <Col span={24}>
                                        <div className="form-lbl-wrapper"><Label required>Email Address</Label></div>
                                    </Col>
                                    <Col span={24}>
                                        <InputBox onChange={(e) => onFormDataChanged('emailAddress', e.target.value)} type="text" value={customerDetails.emailAddress.value} error={customerDetails.emailAddress.hasError} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="eco-mb-24" gutter={[16, 16]}>
                            <Col span={24}>
                                <Row className="form-row">
                                    <Col span={24}>
                                        <div className="form-lbl-wrapper"><Label required>Address Line 1</Label></div>
                                    </Col>
                                    <Col span={24}>
                                        <InputBox onChange={(e) => onFormDataChanged('customerAddress.line1', e.target.value)} type="text" value={customerDetails.customerAddress.line1.value} error={customerDetails.customerAddress.line1.hasError} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="eco-mb-24">
                            <Col span={24}>
                                <Row className="form-row">
                                    <Col span={24}>
                                        <div className="form-lbl-wrapper"><Label>Address Line 2</Label></div>
                                    </Col>
                                    <Col span={24}>
                                        <InputBox onChange={(e) => onFormDataChanged('customerAddress.line2', e.target.value)} type="text" value={customerDetails.customerAddress.line2.value} error={customerDetails.customerAddress.line2.hasError} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="eco-mb-24" gutter={[16, 16]}>
                            <Col lg={6} sm={24} xs={24}>
                                <Row className="form-row">
                                    <Col span={24}>
                                        <div className="form-lbl-wrapper"><Label required>City</Label></div>
                                    </Col>
                                    <Col span={24}>
                                        <InputBox onChange={(e) => onFormDataChanged('customerAddress.city', e.target.value)} type="text" value={customerDetails.customerAddress.city.value} error={customerDetails.customerAddress.city.hasError} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} sm={24} xs={24}>
                                <Row className="form-row">
                                    <Col span={24}>
                                        <div className="form-lbl-wrapper"><Label required>State/County</Label></div>
                                    </Col>
                                    <Col span={24}>
                                        <InputBox onChange={(e) => onFormDataChanged('customerAddress.state', e.target.value)} type="text" value={customerDetails.customerAddress.state.value} error={customerDetails.customerAddress.state.hasError} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} sm={24} xs={24}>
                                <Row className="form-row">
                                    <Col span={24}>
                                        <div className="form-lbl-wrapper"><Label required>Postcode</Label></div>
                                    </Col>
                                    <Col span={24}>
                                        <InputBox onChange={(e) => onFormDataChanged('customerAddress.postcode', e.target.value)} type="text" value={customerDetails.customerAddress.postcode.value} error={customerDetails.customerAddress.postcode.hasError} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} sm={24} xs={24}>
                                <Row className="form-row">
                                    <Col span={24}>
                                        <div className="form-lbl-wrapper"><Label>Country</Label></div>
                                    </Col>
                                    <Col span={24}>
                                        <InputBox type="text" value={customerDetails.customerAddress.country.value} disabled />
                                        {/* <Select noBorder onChange={(value) => onFormDataChanged('customerAddress.country', value)} options={pageState.countryData} value={customerDetails.customerAddress.country.value} hasError={customerDetails.customerAddress.country.hasError} placeholder="" /> */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Space size="middle" className="eco-mb-24">
                            <Checkbox checked={customerDetails.isDeliveryAddressSame} onChange={(e) => toggleIsDeliveryAddressDifferent()} />
                            <Typography type="text">Set the same for delivery</Typography>
                        </Space>

                        {
                            !customerDetails.isDeliveryAddressSame &&
                            <div>
                                <Row className="eco-mb-24" gutter={[16, 16]}>
                                    <Col span={24}>
                                        <Row className="form-row">
                                            <Col span={24}>
                                                <div className="form-lbl-wrapper"><Label required>Address Line 1</Label></div>
                                            </Col>
                                            <Col span={24}>
                                                <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.line1', e.target.value)} type="text" value={customerDetails.deliveryAddress.line1.value} error={customerDetails.deliveryAddress.line1.hasError} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="eco-mb-24">
                                    <Col span={24}>
                                        <Row className="form-row">
                                            <Col span={24}>
                                                <div className="form-lbl-wrapper"><Label>Address Line 2</Label></div>
                                            </Col>
                                            <Col span={24}>
                                                <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.line2', e.target.value)} type="text" value={customerDetails.deliveryAddress.line2.value} error={customerDetails.deliveryAddress.line2.hasError} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="eco-mb-24" gutter={[16, 16]}>
                                    <Col lg={6} sm={24} xs={24}>
                                        <Row className="form-row">
                                            <Col span={24}>
                                                <div className="form-lbl-wrapper"><Label required>City</Label></div>
                                            </Col>
                                            <Col span={24}>
                                                <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.city', e.target.value)} type="text" value={customerDetails.deliveryAddress.city.value} error={customerDetails.deliveryAddress.city.hasError} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6} sm={24} xs={24}>
                                        <Row className="form-row">
                                            <Col span={24}>
                                                <div className="form-lbl-wrapper"><Label required>State/County</Label></div>
                                            </Col>
                                            <Col span={24}>
                                                <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.state', e.target.value)} type="text" value={customerDetails.deliveryAddress.state.value} error={customerDetails.deliveryAddress.state.hasError} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6} sm={24} xs={24}>
                                        <Row className="form-row">
                                            <Col span={24}>
                                                <div className="form-lbl-wrapper"><Label required>Postcode</Label></div>
                                            </Col>
                                            <Col span={24}>
                                                <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.postcode', e.target.value)} type="text" value={customerDetails.deliveryAddress.postcode.value} error={customerDetails.deliveryAddress.postcode.hasError} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6} sm={24} xs={24}>
                                        <Row className="form-row">
                                            <Col span={24}>
                                                <div className="form-lbl-wrapper"><Label>Country</Label></div>
                                            </Col>
                                            <Col span={24}>
                                                <InputBox type="text" value={customerDetails.deliveryAddress.country.value} disabled />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Stack horizontalAlign="h_end">
                <Button variant="primary" className="custom-btn full-width-btn" onClick={() => {
                    validateAllFormData().then((error) => {
                        if (!error) {
                            history.push('/order/summary');
                        }
                    })

                }}>Next</Button>
            </Stack>
        </div>
    )
}
export default OrderConfirmationPage