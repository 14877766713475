import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Row, Col, Typography } from 'antd'
import { Button, Icon } from 'witmeg-ui-system';
import IMAGE_PLACEHOLDER from '../../../../../assets/Images/image-placeholder.png';
import { getCurrencyFormat, getRestaurantMenuProductPrice } from '../../../utilities/helper';
import { getProductItemDataForPopup } from '../../../utilities/restaurantMenuUtil';
import '../../../utilities/stringOverride';
import UpdateCartPopup from '../Common/UpdateCartPopup';
import ShowMoreText from 'react-show-more-text';
import _ from 'lodash';
import ItemPopup from '../Common/ItemPopup';


/*
  Layout: 1
    Shows menu items in one column with images
*/
export default function OneColumnWithImageLayout({ isPopup = false }) {

  const [addToCartModalState, setAddToCartModalState] = useState({ itemId: null, data: null, visible: false });

  const { orderSettings, menuData: { selectedMainMenuData, uiCustomizations }, currency, menuData } = useSelector((state) => state.resturantData);
  const selectedOrderType = orderSettings.urlOrder?.orderType ?? orderSettings.settingsDefaultOrder?.orderType;

  const currencyFormat = getCurrencyFormat(currency);

  const onProductItemClick = async (data) => {
    // const _productData = await getProductItemDataForPopup(data);
    setAddToCartModalState({ itemId: data, visible: true });
  };

  const onPopupClose = () => setAddToCartModalState({ data: null, visible: false });

  return (
    <Row>
      {selectedMainMenuData && selectedMainMenuData.length > 0 && selectedMainMenuData.map((data, index) => {
        return (
          <>


            <Typography type="title" level={4} pattern="bold" style={{ fontWeight: 'bold', fontSize: '18px', color: '#fff', paddingLeft: '1rem' }}>{data?.Title.en.toCapitalFirst()}</Typography>


            {
              data.MenuEntities.map(entity => {
                const _menuItems = _.find(menuData.storage.data.Items, { MenuItemID: entity.ID });
                // const productPrice = getRestaurantMenuProductPrice(_menuItems, selectedOrderType);
                return <>
                  <Col span={24} style={{ padding: '10px', }}>

                    <div style={{ display: 'flex', backgroundColor: '#f5f5f5', borderRadius: '8px', justifyContent: 'space-between', alignItems: "center", padding: "5px 0 5px 0" }}>
                      <div style={{ display: 'flex', padding: '0.5rem 1rem', columnGap: "10px", width: "80%" }}>

                        {_menuItems.ImageURL ?
                          <img alt="" src={_menuItems.ImageURL} style={{ height: '10rem', width: '10rem', borderRadius: "8px", objectFit: "cover", backgroundColor: "#DDDBDD" }} /> : <div style={{ height: '10rem', width: '10rem', borderRadius: "8px", objectFit: "cover", backgroundColor: "#DDDBDD" }} />}

                        <div style={{ padding: '0.5rem' }}>
                          <div>
                            <Typography type="title" level={4} pattern="bold" style={{ fontWeight: 'bold', fontSize: '16px' }}>{_menuItems.Title.en.toCapitalFirst()}</Typography>
                          </div>
                          <div>
                            <Typography type="title" level={5} style={{ padding: '5px 0', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>{_menuItems.Description.en.toCapitalFirst()}</Typography>
                          </div>
                        </div>

                      </div>
                      <div style={{ padding: '0.5rem 1.5rem', textAlign: "right" }}>
                        <Typography type="title" level={5} pattern="bold" style={{ fontWeight: "500", fontSize: '16px', marginBottom: "10px" }}>{currencyFormat(0)}</Typography>
                        <Button variant="primary"
                          onClick={() => onProductItemClick(entity.ID)}>
                          <label>Add to Cart</label>
                        </Button>
                      </div>
                    </div>
                  </Col>

                </>
              })
            }
            {/* {data.MenuEntities.map((data, index) => {
              const productPrice = 0// getRestaurantMenuProductPrice(data, selectedOrderType);
              const isSelected = (addToCartModalState.data && addToCartModalState.data.id === data.ID) ? true : false;
              const productImage = data?.ProductData?.ImageURL && data?.ProductData?.ImageURL.POS && data?.ProductData?.ImageURL.POS.length > 0 ? data?.ProductData?.ImageURL.POS[0] : IMAGE_PLACEHOLDER;
              const productDescription = data?.ProductData?.ItemDescription?.Translations?.en


              return (
                <>
                  <Col span={24} style={{ padding: '10px', }}>
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: '8px', height: '16rem', opacity: '0.8' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.5rem 1rem' }}>
                        <div style={{ padding: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                          <div>
                            <Typography type="title" level={4} pattern="bold" style={{ fontWeight: 'bold', fontSize: '16px' }}>{data.ProductName.toCapitalFirst()}</Typography>
                          </div>
                          <div>
                            <Typography type="title" level={5} style={{ padding: '5px 0', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>{productDescription}</Typography>
                          </div>
                        </div>
                        <img src={productImage} style={{ height: '10rem', width: '10rem', padding: '5px' }} />
                      </div>
                      <div style={{ padding: '0.5rem 1.5rem', justifyContent: 'space-between', display: 'flex' }}>
                        <Typography type="title" level={5} pattern="bold">{currencyFormat(productPrice)}</Typography>
                        <Button variant="primary"
                          onClick={() => onProductItemClick(data)}>
                          <label>Add to Cart</label>
                        </Button>
                      </div>
                    </div>
                  </Col>

                </>
              );
            }
            )} */}
          </>
        );
      })}
      {
        addToCartModalState.itemId && addToCartModalState.visible &&
        <ItemPopup showPopup={addToCartModalState.visible} onPopupClose={onPopupClose} itemId={addToCartModalState.itemId} />
        // <UpdateCartPopup showPopup={addToCartModalState.visible} data={addToCartModalState.data} onPopupClose={onPopupClose} />
      }
    </Row>
  );
}
