import globalValues from "../../../../../utilities/apiRequests/globalValues";


const {
    REACT_APP__LOYALTY_REGISTRY_BASEURL,
    REACT_APP__NEUTRIPOS_BASEURL,
    REACT_APP__RETAILPAXS__BASEURL,
    REACT_APP_PAYMENT_BASE_URL
} = globalValues.baseURLS;
const endpointService = {
    witmegRetailPaxServer: {
        getCompanyByDomain: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/company/domain`,
        getCompanyAllHomeLayouts: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/homelayout/company`,
        getPageHomeLayout: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/homelayout/page`,
        getCompanyAllPagesInfo: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/pagesinfo/all/companyid`,
        getHomeLayoutById: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/homelayout/id`,
        getPageInfoById: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/pagesinfo/id`,
    },
    loyaltyRegistryServer: {
        getAllRestaurantMenuItems: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/menu`,
        addItemToCart: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/order/add`,
        order: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/order`,
        placeOrder: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/place-order`,
        updateOrderType: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/order-type/change`,
        addToCart: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/order/add`,
        getOrderHistory: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/order/history`
    },
    nuetriposServer: {
        getFloorData: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/resttable`
    },
    paymentServer: {
        getStorePaymentMethods: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/paymentmethod/fetch`,
        passMethodDataToDropIn: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/payment/instant`,
        sendPaymentDetails: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/pay/paymentdetail`
    }

};

export default endpointService;

