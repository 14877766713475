import React, { useEffect } from "react";
import Navbar from "./navbar.component";
import { useSelector } from "react-redux";

const DefaultPageWrapper = ({ navbarPageClickCallback, children }) => {
  const { defaultOrderType } = useSelector((state) => state.resturantData.cartDetails);
  const isOrderTypeDineIn = defaultOrderType && defaultOrderType === 'table';
  return (
    <>
      <div className="page-wrapper">
        <div style={{ paddingLeft: "10px", paddingRight: "10px", width: "100%" }}><Navbar parentPageCallback={navbarPageClickCallback} /></div>
        <div className={`page-content ${isOrderTypeDineIn ? 'eco-dine-in-page' : ''}`}>
          {children}
        </div>
      </div>
    </>
  );
}

export default DefaultPageWrapper;