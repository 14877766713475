import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router';
import "./HospitalityDefaultOrderPage.scss"
import sucessIc from "../../assets/success.svg"
import { getOrderDetails } from '../../../RestaurantWidget/utilities/api/restaurantsService';
import { useDispatch } from "react-redux";
import { LOCAL_STORAGE_ITEMS_NAMES } from '../../../RestaurantWidget/utilities/helper/helper';
import { setOrderTrackingData } from '../../../../../../../redux/resturantWidgetSlice';

const HospitalityDefaultOrderSuccessPage = () => {
    const storeOrderLocalData = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)) : null
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        localStorage.removeItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_TRACK_ORDER)
    }, [])
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch()

    const onClickBackToMenu = () => history.push("/");

    return (
        <div className='Order-success-form-deafult'>
            {isLoading &&
                <div className="overlayer" ><div className="order-placing">Loading ...</div></div>
            }
            <img alt='' src={sucessIc} className='sucessIc' />
            <div className="main-message-text">Your order has been accepted.</div>
            <div lassName="sub-message-text-cont">Your Order ID is : {params.id}</div>
            <div type="text" className="card-payment-desc-text">
                Your order has been now confirmed! <br />
                We're thrilled to have you dine with us and can't wait for you to enjoy your meal. Bon Appétit!</div>
            <div className='btn-conatiner'>
                <div onClick={onClickBackToMenu} className='menu-btn'>Back to Menu</div>
                <div onClick={() => {
                    if (params.id !== "" && !isLoading) {
                        setIsLoading(true)
                        getOrderDetails(params.id, storeOrderLocalData.cloudLocationID).then((response) => {
                            if (response) {
                                dispatch(setOrderTrackingData(response))
                                setIsLoading(false)
                                history.push("/order/status")

                            }
                        }).catch(() => {
                            setIsLoading(false)
                        })
                    }
                }} className='menu-btn-sec'><div className='blue-loader' />Track Your Order</div>

            </div>

        </div>
    )
}

export default HospitalityDefaultOrderSuccessPage