import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Button, Icon } from 'witmeg-ui-system';
import { getCurrencyFormat, getRestaurantMenuProductPrice } from '../../../utilities/helper';
import { getProductItemDataForPopup } from '../../../utilities/restaurantMenuUtil';
import '../../../utilities/stringOverride';
import UpdateCartPopup from '../Common/UpdateCartPopup';
import ShowMoreText from 'react-show-more-text';
import { Row, Col, Typography } from 'antd'

/*
  Layout: 2
    Shows menu items in one column without images
*/
export default function OneColumnWithoutImageLayout({ isPopup = false }) {

  const [addToCartModalState, setAddToCartModalState] = useState({ data: null, visible: false });

  const { orderSettings, menuData: { selectedMainMenuData, uiCustomizations }, currency } = useSelector((state) => state.resturantData);

  const selectedOrderType = orderSettings.urlOrder?.orderType ?? orderSettings.settingsDefaultOrder?.orderType;

  const currencyFormat = getCurrencyFormat(currency);

  const onProductItemClick = async (data) => {
    const _productData = await getProductItemDataForPopup(data);
    if (_productData) setAddToCartModalState({ data: _productData, visible: true });
  };

  const onPopupClose = () => setAddToCartModalState({ data: null, visible: false });

  return (
    <Row>
      {selectedMainMenuData && selectedMainMenuData.SubMenu.length > 0 && selectedMainMenuData.SubMenu.map((data, index) => {
        return (
          <>
            {uiCustomizations.subMenu.settings.subMenuEnabled && (<label style={uiCustomizations.subMenu.style}>{data?.SubMenuName.toCapitalFirst()}</label>)}

            {data.MenuProductData.map((data, index) => {
              const productPrice = getRestaurantMenuProductPrice(data, selectedOrderType);
              const isSelected = (addToCartModalState.data && addToCartModalState.data.id === data.ID) ? true : false;
              const productDescription = data?.ProductData?.ItemDescription?.Translations?.en

              return (
                <Col span={24} style={{ padding: '10px', }}>
                  <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: '8px', height: '16rem', opacity: '0.8' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.5rem 1rem' }}>
                      <div style={{ padding: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                        <div>
                          <Typography type="title" level={4} pattern="bold" style={{ fontWeight: 'bold', fontSize: '16px' }}>{data.ProductName.toCapitalFirst()}</Typography>
                        </div>
                        <div style={{ height: "7rem" }}>
                          <Typography type="title" level={5} style={{ padding: '5px 0', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>{productDescription}</Typography>
                        </div>
                      </div>
                      {/* <img src={productImage} style={{ height: '10rem', width: '10rem', padding: '5px' }} /> */}
                    </div>
                    <div style={{ padding: '0.5rem 1.5rem', justifyContent: 'space-between', display: 'flex' }}>
                      <Typography type="title" level={5} pattern="bold">{currencyFormat(productPrice)}</Typography>
                      <Button variant="primary"
                        onClick={() => onProductItemClick(data)}>
                        <label>Add to Cart</label>
                      </Button>
                    </div>
                  </div>
                </Col>
                // <Col span={isPopup ? 20 : 10}>
                //   <Stack direction="column" verticalAlign="v_start">
                //     <div className={isPopup ? '' : 'product-name-label'}>
                //       <label style={uiCustomizations.productItem.name}>
                //         {data.ProductName.toCapitalFirst()}
                //       </label>
                //     </div>
                //     <div className={isPopup ? '' : 'product-description'}>
                //       <label style={uiCustomizations.productItem.description}>
                //         <ShowMoreText
                //           lines={2}
                //           more="More"
                //           less="Less"
                //           expanded={false}
                //           width={360}
                //           truncatedEndingComponent={"..."}>
                //           {data.ProductData.ItemDescription.Translations.en.toCapitalFirst()}
                //         </ShowMoreText>
                //       </label>
                //     </div>
                //   </Stack>
                // </Col>
                // <Col span={4} style={isPopup ? { textAlign: 'right' } : { textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'baseline' }}>
                //   <span style={{ marginRight: '8px' }}>{data.ProductData?.ServicePriceInfo?.Price !== null && <label style={uiCustomizations.productItem.name}>{currencyFormat(productPrice)}</label>}</span>
                //   {window.location.hostname !== "kottuking.witmeg.net" ?
                //     <Button
                //       variant="primary"
                //       className="eco-add-to-cart-label"
                //       onClick={() => onProductItemClick(data)}
                //       style>
                //       <label className='desktop-but'>Add to Cart</label>
                //       <label className='mobile-but'><Icon name="plus" size={24} /></label>
                //     </Button>
                //     : null}
                // </Col>

              );
            })}
          </>
        );
      })}
      {
        addToCartModalState.data && addToCartModalState.data.ProductName !== "" && addToCartModalState.visible &&
        <UpdateCartPopup showPopup={addToCartModalState.visible} data={addToCartModalState.data} onPopupClose={onPopupClose} />
      }
    </Row>
  );
}
