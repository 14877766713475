import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Popup } from "witmeg-ui-system";
import './Layouts/layout.css';
import { Button } from "antd";
import config from "react-global-configuration";

const PopupWrapper = ({ children, isPopup = false }) => {
  const [popupState, setPopupState] = useState({
    visible: false,
    isHover: false,
  });

  const { menuData: { uiCustomizations }, cartDetails: { defaultOrderType } } = useSelector((state) => state.resturantData);

  const changePopupVisibility = (_value) => {
    setPopupState(prevState => ({ ...prevState, visible: _value, }));
  }

  const changeMouseHoverState = (_value) => {
    setPopupState(prevState => ({ ...prevState, isHover: _value, }));
  }

  const backgroundThemeStyle = uiCustomizations?.layout?.settings?.theme === 'removeTheme' ? uiCustomizations.layout?.default : uiCustomizations.layout?.custom;

  const backgroundLayoutStyle = {
    ...backgroundThemeStyle,
    height: 'calc(100vh - 60px)',
    overflowY: 'auto'
  };

  const isOrderTypeDineIn = defaultOrderType && defaultOrderType === 'table';

  return (
    isPopup ?
      (
        <>
          <div style={{ height: '100%' }}>
            <Button
              type="primary"
              onClick={() => changePopupVisibility(true)}
              style={{
                ...uiCustomizations?.popup?.buttonStyle,
                background:
                  (popupState.isHover && uiCustomizations?.popup?.hoverBackgroundColor) ?
                    uiCustomizations?.popup?.hoverBackgroundColor :
                    uiCustomizations?.popup?.buttonStyle?.background ?? ''
              }}
              onMouseEnter={() => changeMouseHoverState(true)}
              onMouseLeave={() => changeMouseHoverState(false)}>
              <div style={{ fontFamily: uiCustomizations.popup.buttonStyle.fontFamily }}>
                <span style={{ fontSize: uiCustomizations.popup.buttonStyle.fontSize, color: uiCustomizations?.popup?.buttonStyle?.color }}>
                  {uiCustomizations.popup.settings.name}
                </span>
              </div>
            </Button>
            <Popup
              width={1200}
              className="menu-popup"
              modalVisible={popupState.visible}
              onCancel={() => changePopupVisibility(false)}>
              <div className="one-col-layout eco-theme-dashbord" style={uiCustomizations.layout.custom}>
                {children}
              </div>
            </Popup>
          </div>
        </>
      ) :
      (
        <>
          <div
            className={`one-col-layout eco-theme-dashbord eco-theme-dashboard-embedded ${isOrderTypeDineIn ? 'eco-dine-in-menu' : ''}`}
            style={config.get('Theme') === "Default" ? backgroundLayoutStyle : {}}
          >
            {children}
          </div >
        </>
      )
  );
}

export default PopupWrapper;