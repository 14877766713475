import React from 'react';
import { Button, Popup, Stack, Typography } from 'witmeg-ui-system';

const DeletePopup = ({ visibility, onDeleteConfirm, onPopupClose }) => {

    return (
        <Popup
            className="delete-cartitem-popup-close"
            modalVisible={visibility}
            modalTitle=""
            mask={true}
            centered
            onCancel={onPopupClose}
            closable={false}>
            <div className='delete-cartitem-icon eco-mh-20'></div>
            <Typography type="text" pattern="bold" className="eco-mh-20">
                <h2>Are you sure want to remove it from cart?</h2>
            </Typography>
            <Stack className="eco-mt-28 eco-m-8">
                <Button variant="primary" className="eco-mr-20 eco-pl-16 eco-pr-16" onClick={onDeleteConfirm}>
                    Remove
                </Button>
                <Button variant="outline" className="eco-pl-16 eco-pr-16" onClick={onPopupClose}>
                    Cancel
                </Button>
            </Stack>
        </Popup>
    );
}

export default DeletePopup;