import React from "react";
import "./HospitalityDefaultOrderPage.scss"
import { useSelector } from "react-redux";
import locationIC from "../../assets/location.svg"
import moment from 'moment';
import Footer from "../../components/Footer/Footer";



const HospitalityDefaultOrderStatusPage = () => {
    const { orderTrackingData } = useSelector((state) => state.resturantData);
    const customerDetails = orderTrackingData?.Customers[0]
    const isCartDataAvailable = orderTrackingData?.Carts?.Items?.length > 0 ? true : false
    const companyDetails = useSelector((state) => state.companyData.companyDetails);

    const steps = [{
        head: "Order Confirmation",
        sub: "Your order has confirmed",
        status: 'CREATED'
    }, {
        head: "Order Preparing",
        sub: "Restaurant is preparing your food",
        status: 'PREPARING'

    }, {
        head: "Delivery in Progress",
        sub: "Your order will be delivered soon",
        status: 'SCHEDULED'

    }, {
        head: "Order Completed",
        sub: `Thank You for ordering at ${orderTrackingData?.Store?.Name}`,
        status: 'FINISHED'

    }
    ]
    const getPrice = (
        basePrice
    ) => {
        let priceValue;
        switch (orderTrackingData.FulfillmentType) {
            case "DELIVERY_BY_REWARDCONSUMER":
                priceValue = basePrice.Delivery.FormattedText;
                break;
            case "DINE_IN":
                priceValue = basePrice.DineIn.FormattedText;
                break;
            case "TAKE_AWAY":
                priceValue = basePrice.TakeAway.FormattedText;
                break;
            default:
                priceValue = basePrice.Delivery.FormattedText;
                break;
        }
        return priceValue
    }
    const date = moment(new Date(orderTrackingData.ScheduledOrderTargetDeliveryTimeRange.EndTime)).format('D MMM, YYYY')

    return (
        <div className='HospitalityDefaultOrderPage '>
            <div className="Order-confirmation">
                <div className="Order-confirmation-header">
                    <div className="header-text-cont">Track your order</div>

                </div>
                <div className="Order-confirmation-inner">
                    <div className="eco-mb-16 Order-confirmation-inner-left ">
                        <div className="order-form-summary  eco-mb-36">
                            <div className="order-form-container">
                                <div className="form-header-text">Delivery Address</div>
                                <div className="address-conatinar">
                                    <div className="address-conatinar-left">
                                        <img className="loc-cont" alt="" src={locationIC} />
                                        <div className="customer-details-cont">
                                            <div className="customer-name-cont">
                                                <div className="name-text">{customerDetails?.Name?.FirstName} {customerDetails?.Name?.LastName}</div>
                                                <div className="address-tag">Main Address</div>
                                            </div>
                                            <div className="phone-number">{customerDetails.Contact.PinCode} {customerDetails.Contact.Number}</div>
                                            <div className="address">                                            {customerDetails?.BillingAddress?.AddressLine1} {customerDetails?.BillingAddress?.StateProvinceRegion}, {customerDetails?.BillingAddress?.Country} {customerDetails?.BillingAddress?.PostZipCode} </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="order-form-container">
                                <div className="form-header-text">Estimated delivery: {date}</div>
                                <div className="track-steps-container">
                                    {steps.map((step, index) => {
                                        let stepClass = '';
                                        if (orderTrackingData.State === step.status) {
                                            stepClass = '-current-step';
                                        } else if (index < steps.findIndex(s => s.status === orderTrackingData.State)) {
                                            stepClass = '-completed-step';
                                        } else {
                                            stepClass = '-upcoming-step';
                                        }

                                        return (
                                            <div className={`steps-container${stepClass}`} key={index}>
                                                <div className="loc-container"><div className="inner-loc" /></div>
                                                {index + 1 !== steps.length && <div className="left-border-step" />}
                                                <div>
                                                    <div className="content-head">{step.head}</div>
                                                    <div className="content-track">{step.sub}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="Order-confirmation-inner-right">
                        {isCartDataAvailable ? <div className="order-form-summary">
                            <div className="order-form-container">
                                <div className="form-header-text">Order Summary</div>
                                {isCartDataAvailable && orderTrackingData.Carts.Items.map((item, index) => {
                                    return (
                                        <div className="product-summary" key={index}>  <div className='truncate-text'>{item.Title}</div>
                                            <div>  {getPrice(item.PriceInfo.TotalPrice)}</div>
                                        </div>
                                    )
                                })}
                                <div className="price-list">
                                    <div className="form-header-text">Order Total</div>
                                    <div className="price-list-inner">
                                        <div className="price-list-inner-left">Sub total</div>
                                        <div className="price-list-inner-right">     {orderTrackingData.Payment.
                                            PaymentDetail.OrderTotal.Net.Formatted}</div>

                                    </div>
                                    {orderTrackingData?.Payment.
                                        PaymentDetail?.Fee?.Details?.map((fee, key) => {
                                            return (
                                                <div className="price-list-inner" key={key}>
                                                    <div className="price-list-inner-left">{fee.Description}</div>
                                                    <div className="price-list-inner-right">
                                                        {fee.Amount.DisplayAmount}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                                <div className="price-list">
                                    <div className="price-list-inner-total" >
                                        <div className="total-value-text ">Total Price</div>
                                        <div className="total-value-text ">
                                            {orderTrackingData?.Payment.
                                                PaymentDetail.OrderTotal.Gross.Formatted}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""}
                    </div>
                </div>
                <Footer />
            </div>
        </div>

    )
}
export default HospitalityDefaultOrderStatusPage