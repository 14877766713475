import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router'
import { Button } from 'witmeg-ui-system'
import "./OrderPage.scss"

import "@fontsource/inter";
import OrderConfirmationPage from './OrderConfirmationPage';
import { LOCAL_STORAGE_ITEMS_NAMES } from '../../utilities/helper/helper';

const OrderPage = () => {

    let history = useHistory();
    const companyDetails = useSelector((state) => state.companyData.companyDetails);
    const orderID = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) ? localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) : ""

    const onBackToMenu = () => {
        history.push("/");
    }

    useEffect(() => {
        if (orderID === "") {
            history.push("/")
        }
    }, [])


    return (
        <div className='OrderPage order-confirmation-page'>
            <Button className="eco-order-confirm-back-but eco-mb-24" category="icon-text" icon="chevron-left" text={companyDetails.websiteType === "Restaurant" ? "Back to menu" : "Back"} iconSize={16} variant="subtle" onClick={onBackToMenu} />
            <OrderConfirmationPage />
        </div>
    )
}

export default OrderPage