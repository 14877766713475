import React from 'react'
import { useHistory } from 'react-router';
import { Button, Icon, Stack, Typography } from 'witmeg-ui-system'

const OrderFailedPage = () => {

    const history = useHistory();

    const onClickBackToMenu = () => history.push("/");

    return (
        <div className='order-success-form'>
            <div>
                <Stack horizontalAlign="h_center" direction="column" className="order-success-form-container">
                    <Icon name="close-circle" size={80} className="eco-mb-44 order-danger-icon" />
                    <Typography type="title" level={4} pattern="bold" className="eco-mb-16 main-message">Your order could not be processed</Typography>

                    <Typography type="text" className="eco-mb-24 card-payment-desc" alignment="center" color="gray_base">
                        Please try again, or contact support.</Typography>
                    <Button onClick={onClickBackToMenu}>Back to Menu</Button>
                </Stack>
            </div>
        </div>
    )
}

export default OrderFailedPage