import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, Col, Radio, Row, Stack, Typography } from "witmeg-ui-system";

const SelectOrderTypePrompt = ({ cloudLocationID }) => {

  let history = useHistory();

  const qrCodeURLs = useSelector((state) => state.resturantData.qrCodeURLs);

  const [pageState, setPageState] = useState({
    isDelivery: true,
    isLoading: false
  });

  const onOrderTypeChange = (_value) => {
    setPageState(prevState => ({ ...prevState, isDelivery: _value }));
  }
  console.log(qrCodeURLs)
  const onClickProceed = () => {
    let _path = qrCodeURLs.delivery;
    console.log(_path, "_path")
    if (!pageState.isDelivery) _path = qrCodeURLs.collection;

    // history.push(_path);
    // window.location.replace(_path);
  }
  return (
    <div className='select-order-type-prompt'>
      <div style={{ maxWidth: '500px', width: '100%', padding: '0px 5px' }}>
        <Stack horizontalAlign="h_center">
          <Typography type="title" level={1} className="eco-mb-32" color="gray_darkest" alignment="center">Select Order Type</Typography>
        </Stack>
        <div>
          <div style={{ border: '1px solid #C6C7D2', borderRadius: '8px', width: '100%' }} className="eco-mb-16" >
            <div style={{ backgroundColor: '#F0F1F4', borderRadius: '8px 8px 0px 0px', padding: '15px 20px' }}>
              <Typography type="text">How do you want to order</Typography>
            </div>
            <div style={{ padding: '20px 30px' }}>
              <Row>
                <Col span={24}>
                  <Row className="eco-mb-24" gutter={[0, 32]}>
                    <Col span={12}>
                      <Stack horizontalAlign="space_around">
                        <Radio value="collection" checked={!pageState.isDelivery} onChange={(e) => onOrderTypeChange(false)}>Collection</Radio>
                        <Radio value="delivery" checked={pageState.isDelivery} onChange={(e) => onOrderTypeChange(true)}>Delivery</Radio>
                      </Stack>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <Col span={24} style={{ textAlign: 'right', display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button variant="primary" onClick={onClickProceed}>Proceed</Button>
          </Col>
        </div>
      </div>
    </div>
  );
}

export default SelectOrderTypePrompt;