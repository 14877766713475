import React from 'react'
import { useHistory, useParams } from 'react-router';
import { Button, Icon, Stack, Typography } from 'witmeg-ui-system'

const OrderSuccessPage = () => {

    const params = useParams();
    const history = useHistory();

    const onClickBackToMenu = () => history.push("/");

    return (
        <div className='order-success-form'>
            <div>
                <Stack horizontalAlign="h_center" direction="column" className="order-success-form-container">
                    <Icon name="check-circle" size={80} className="eco-mb-44 order-success-icon" />
                    <Typography type="title" level={4} pattern="bold" className="eco-mb-16 main-message">Your order has been accepted.</Typography>
                    <Typography type="title" level={5} className="eco-mb-24">Your Order ID is : {params.id}</Typography>
                    <Typography type="text" className="eco-mb-24 card-payment-desc" alignment="center" color="gray_base">
                        Your order has been now confirmed! <br />
                        We're thrilled to have you dine with us and can't wait for you to enjoy <br /> your meal. Bon Appétit!</Typography>
                    <Button onClick={onClickBackToMenu}>Back to Menu</Button>
                </Stack>
            </div>
        </div>
    )
}

export default OrderSuccessPage