import React from 'react';
import "./CodeInput.scss"

const CodeInput = ({ codes, setCodes }) => {


    const handleInputChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]?$/.test(value)) {
            const newCodes = [...codes];
            newCodes[index] = value;
            setCodes(newCodes);
            if (value && index < codes.length - 1) {
                document.getElementById(`code-input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !codes[index] && index > 0) {
            document.getElementById(`code-input-${index - 1}`).focus();
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData("text").slice(0, 6);
        if (/^[0-9]+$/.test(paste)) {
            const pasteCodes = paste.split("");
            setCodes(codes.map((_, i) => pasteCodes[i] || ""));
        }
    };

    return (
        <div onPaste={handlePaste} className='CodeInput'>
            {codes.map((code, index) => (
                <input
                    key={index}
                    id={`code-input-${index}`}
                    type="text"
                    inputMode="numeric"
                    maxLength="1"
                    value={code}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className='code-input-styles ' />
            ))}
        </div>
    );
};

export default CodeInput;
