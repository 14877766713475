import styled from "styled-components"
import { Accordion, Alert, Button, Checkbox, Icon, Message, Popup, Stack, TextArea, Typography } from 'witmeg-ui-system';
import { Form, Input, Select, Divider, Modal } from 'antd';



export const ItemContainer = styled("div")`
width:100%;
`

export const ModifiersWrapper = styled("div")`
display:flex;
flex-direction:row;
width:100%;
`

export const ModifierOptionContainer = styled("div")`
display:flex;
flex-direction:row;
width:100%;
`

export const QuantityContainer = styled("div")`
display:flex;
flex-direction:row;
`
export const DecreaseQuantityButton = styled("div")`
border-top-left-radius:6px;
border-bottom-left-radius:6px;
border:1px solid #EAE8ED;
border-right-color:transparent;
width:2rem;
`

export const IncreaseQuantityButton = styled("div")`
border-top-right-radius:6px;
border-bottom-right-radius:6px;
border:1px solid #EAE8ED;
border-left-color:transparent;
width:2rem;
`

export const QuantityTextbox = styled("input")`
width:3rem;
border:1px solid #EAE8ED;
text-align:center;
`