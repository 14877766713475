import THEME_1_BG_IMAGE from '../../default/images/resturant-menu/resturant-menu-theme-1.jpg';
import THEME_2_BG_IMAGE from '../../default/images/resturant-menu/resturant-menu-theme-2.jpg';
import THEME_3_BG_IMAGE from '../../default/images/resturant-menu/resturant-menu-theme-3.jpg';
import THEME_4_BG_IMAGE from '../../default/images/resturant-menu/resturant-menu-theme-4.jpg';
import { DividerStyleCategory } from './restaurantMenuUtil';

const getDividerStyles = (divider) => {
  return divider && divider.enabled
    ? {
      borderBottomStyle:
        divider.category &&
          (divider.category === DividerStyleCategory.DIVIDER_3 || divider.category === DividerStyleCategory.DIVIDER_4)
          ? 'dashed'
          : 'solid',
      borderBottomColor: divider.color ?? '#000000',
      borderBottomWidth: divider.style
        ? divider.category === DividerStyleCategory.DIVIDER_1
          ? `${divider.style / 2}px` : `${divider.style}px`
        : '1px',
    }
    : { borderBottom: 'none' };
};

export const getRestaurantMenuUICustomizations = (customizations, isPopup, layout) => {
  const { subCatFormData, itemsFormData, categoriesFormData, spaceFormData, buttonFormData, selectedTheme } = customizations;

  const _tabs = {
    title: {
      color: `${categoriesFormData.catFontColor ? categoriesFormData.catFontColor : '#FD8F0E'}`,
      fontSize: `${categoriesFormData.catFontSize ? categoriesFormData.catFontSize : 16}px`,
      fontWeight: `${categoriesFormData.bold ? '700' : '500'}`,
      fontStyle: `${categoriesFormData.italic ? 'italic' : 'normal'}`,
      textDecoration: `${categoriesFormData.underline ? 'underline' : 'none'}`,
      background: '#FFF !important',
      width: '100% !important',
      fontFamily: categoriesFormData?.catFontFamily,
    },
  };

  const _subMenu = {
    settings: {
      subMenuEnabled: subCatFormData.showSubMenu ? true : false,
      dividerEnabled: subCatFormData.divider?.enabled ? true : false,
    },
    style: {
      color: subCatFormData.subFontColor ? subCatFormData.subFontColor : "#000",
      fontSize: `${subCatFormData.subCatFontSize ? subCatFormData.subCatFontSize : 16}px`,
      fontWeight: `${subCatFormData.bold ? '700' : '500'}`,
      fontStyle: `${subCatFormData.italic ? 'italic' : 'normal'}`,
      fontDecoration: `${subCatFormData.underline ? 'underline' : 'none'}`,
      borderBottom: `${subCatFormData.subDivider ? "5px solid" : "none"}`,
      borderBottomWidth: `${subCatFormData.subCatDividerStyle}px`,
      // marginTop: '10px',
      fontFamily: subCatFormData?.subCatFontFamily,
      ...getDividerStyles(subCatFormData?.divider),
    },
  }

  const _productItem = {
    name: {
      color: itemsFormData.itemNameFontColor ? itemsFormData.itemNameFontColor : '#000',
      fontSize: `${itemsFormData.nameItemFontSize ? itemsFormData.nameItemFontSize : 16}px`,
      fontWeight: `${itemsFormData.namebold ? '700' : '500'}`,
      ftStyle: `${itemsFormData.nameitalic ? 'italic' : 'normal'}`,
      fontDecoration: `${itemsFormData.nameunderline ? 'underline' : 'none'}`,
      fontFamily: itemsFormData?.nameItemFontFamily,
    },
    description: {
      color: itemsFormData.descFontColor ? itemsFormData.descFontColor : "#000",
      fontSize: `${itemsFormData.descFontSize ? itemsFormData.descFontSize : 16}px`,
      fontWeight: `${itemsFormData.descbold ? '700' : '500'}`,
      fontStyle: `${itemsFormData.descitalic ? 'italic' : 'normal'}`,
      fontDecoration: `${itemsFormData.descunderline ? 'underline' : 'none'}`,
      fontFamily: itemsFormData?.descFontFamily,
    },
    itemSpaceBetween: {
      marginBottom: `${itemsFormData?.descSpaceItems}px`
    },
    block: {
      ...getDividerStyles(itemsFormData?.divider),
    },
  };

  let _backgroundImage = null;
  if (selectedTheme === 'themeOne') _backgroundImage = THEME_1_BG_IMAGE;
  if (selectedTheme === 'themeTwo') _backgroundImage = THEME_2_BG_IMAGE;
  if (selectedTheme === 'themeThree') _backgroundImage = THEME_3_BG_IMAGE;
  if (selectedTheme === 'themeFour') _backgroundImage = THEME_4_BG_IMAGE;

  const _layout = {
    settings: {
      theme: selectedTheme,
    },
    default: {
      paddingTop: spaceFormData.top ? spaceFormData.top : '20px',
      paddingRight: spaceFormData.right ? spaceFormData.right : '10px',
      paddingBottom: spaceFormData.bottom ? spaceFormData.bottom : '30px',
      paddingLeft: spaceFormData.left ? spaceFormData.left : '30px',
      minHeight: 'auto',
      maxHeight: 'auto',
      background: `#fff`,
    },
    custom: {
      paddingTop: spaceFormData.top ? spaceFormData.top : '20px',
      paddingRight: spaceFormData.right ? spaceFormData.right : '10px',
      paddingBottom: spaceFormData.bottom ? spaceFormData.bottom : '30px',
      paddingLeft: spaceFormData.left ? spaceFormData.left : '30px',
      background: _backgroundImage ? `url(${_backgroundImage})` : '#fff',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      minHeight: 'auto',
      maxHeight: 'auto',
    },
  };

  const _popup = {
    settings: {
      name: buttonFormData?.buttonName ?? 'Order Online',
    },
    styles: {
      color: `${buttonFormData?.btnFontColor ? buttonFormData.btnFontColor : "#FD8F0E"}`,
      fontSize: `${buttonFormData?.btnFontSize ? buttonFormData.btnFontSize : 16}px`,
      fontWeight: `${buttonFormData?.bold ? '700' : '500'}`,
      fontStyle: `${buttonFormData?.italic ? 'italic' : 'normal'}`,
      fontDecoration: `${buttonFormData?.underline ? 'underline' : 'none'}`,
      background: `${buttonFormData?.backgroundColor ? buttonFormData.backgroundColor : "#FFF !important"}`,
      width: "100%",
      height: "100%",
      border: `1px solid ${buttonFormData?.borderColor}`,
      borderRadius: `${buttonFormData?.curvedEdges}px`,
      fontFamily: buttonFormData?.btnFontFamily
    },
    buttonStyle: {
      fontFamily: buttonFormData?.btnFontFamily,
      fontSize: `${buttonFormData?.btnFontSize ?? 16}px`,
      height: '100%',
      width: '100%',
      background: buttonFormData?.backgroundColor ?? '',
      border: buttonFormData?.borderColor ? `3px solid ${buttonFormData.borderColor}` : '',
      borderRadius: buttonFormData?.curvedEdges ? `${buttonFormData.curvedEdges}px` : '',
      color: buttonFormData?.btnFontColor ?? ''
    },
    hoverBackgroundColor: buttonFormData?.hoverBackgroundColor ?? '',
  };

  return {
    tabs: _tabs,
    subMenu: _subMenu,
    productItem: _productItem,
    layout: _layout,
    popup: _popup,
  };
}